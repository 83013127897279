import React, { useState } from "react";
import RichTextEditor from "react-rte";

function CustomRichTextEditor({ label, onComplete, defaultValue }) {
  const toolbarConfig = {
    display: ["INLINE_STYLE_BUTTONS", "BLOCK_TYPE_BUTTONS", "LINK_BUTTONS"],
    INLINE_STYLE_BUTTONS: [
      { label: "Bold", style: "BOLD", className: "custom-css-class" },
      { label: "Italic", style: "ITALIC" },
    ],
    BLOCK_TYPE_BUTTONS: [
      { label: "UL", style: "unordered-list-item" },
      { label: "OL", style: "ordered-list-item" },
    ],
  };

  const [state, setState] = useState({
    value: defaultValue
      ? RichTextEditor.createValueFromString(defaultValue, "markdown")
      : RichTextEditor.createEmptyValue(),
  });

  const onValueChange = (value) => {
    setState({ value });
  };

  return (
    <RichTextEditor
      className="rich-text-editor"
      toolbarConfig={toolbarConfig}
      value={state.value}
      onChange={onValueChange}
      placeholder={label}
      onBlur={() => {
        onComplete(state.value.toString("markdown"));
      }}
    />
  );
}

export default CustomRichTextEditor;
