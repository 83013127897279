import React, { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
  Button,
} from "@material-ui/core";
import { CORP_LOGIN } from "../../utils/mutations";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import MailIcon from "@material-ui/icons/Mail";
import { Link, useHistory } from "react-router-dom";
import { validateEmail, validatePassword, Loader } from "../../utils/utilities";
import { useMutation } from "@apollo/react-hooks";
import { AUTH_TOKEN, REFRESH_TOKEN } from "../../utils/constants";

export default function Login(props) {
  const history = useHistory();

  const [credentials, setCredentials] = useState({
    emailId: "",
    emailError: false,
    password: "",
    passwordError: false,
    showPassword: false,
    otherError: "",
    loading: false,
  });

  const [performLogin] = useMutation(CORP_LOGIN);

  const OtherError = () => {
    if (credentials.otherError) {
      return (
        <div>
          <p style={{ color: "red" }}>{credentials.otherError}</p>
        </div>
      );
    }
    return null;
  };

  const handleOnChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setCredentials((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const onSubmit = (event) => {
    event.preventDefault();

    //Reset on Re-Submit.
    setCredentials((preValue) => ({
      ...preValue,
      invalidCredential: false,
      otherError: "",
    }));
    if (validate()) {
      handleLogin();
    }
  };

  const validate = () => {
    const { emailId, password } = credentials;
    let emailStatus, passwordStatus;

    emailStatus = validateEmail(emailId);
    passwordStatus = validatePassword(password);
    setCredentials((preValue) => ({
      ...preValue,
      emailError: !emailStatus,
      passwordError: !passwordStatus,
    }));

    return emailStatus && passwordStatus;
  };

  const handleLogin = () => {
    setCredentials((preValue) => ({
      ...preValue,
      loading: true,
    }));
    const { emailId, password } = credentials;
    performLogin({
      variables: {
        email: emailId,
        password: password,
      },
    })
      .then((result) => {
        const { token, user, refreshToken } = result.data.corporateLogin;
        localStorage.setItem(AUTH_TOKEN, token);
        localStorage.setItem(REFRESH_TOKEN, refreshToken);
        const userDetails = {
          id: user.id,
          firstName: user.firstName,
          stripeId: user.stripeId,
        };
        props.contextState.setUser(userDetails);
        history.replace(props.loc);
      })
      .catch((error) => {
        const { message } = error;
        switch (message) {
          case "GraphQL error: Please, enter valid credentials":
            setCredentials((preValue) => ({
              ...preValue,
              otherError: "Please enter a valid email and password to sign in.",
              loading: false,
            }));
            break;
          case "Network error: Failed to fetch":
            setCredentials((prevState) => ({
              ...prevState,
              otherError: "Please check your network connection and try again.",
              loading: false,
            }));
            break;
          default:
            setCredentials((prevState) => ({
              ...prevState,
              otherError: message.substring(message.indexOf(":") + 1),
              loading: false,
            }));
        }
      });
  };

  return (
    <Box className="login-container">
      <Typography variant="h5">LOG IN</Typography>
      <Typography variant="subtitle1">Hi there! Let's get started.</Typography>
      <form noValidate>
        <TextField
          variant="filled"
          margin="normal"
          required
          fullWidth
          id="email"
          placeholder="Email"
          name="emailId"
          autoComplete="email"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <MailIcon color="primary" />
              </InputAdornment>
            ),
          }}
          error={credentials.emailError}
          helperText={
            credentials.emailError && "Please enter a valid email address."
          }
          onChange={handleOnChange}
          autoFocus
        />
        <TextField
          variant="filled"
          margin="normal"
          required
          fullWidth
          name="password"
          placeholder="Password"
          type={credentials.showPassword ? "text" : "password"}
          id="password"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => {
                    setCredentials((prevState) => ({
                      ...prevState,
                      showPassword: !prevState.showPassword,
                    }));
                  }}
                >
                  {credentials.showPassword ? (
                    <Visibility color="primary" />
                  ) : (
                    <VisibilityOff color="primary" />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
          error={credentials.passwordError}
          helperText={
            credentials.passwordError && "Please enter a valid password."
          }
          onChange={handleOnChange}
        />
        <OtherError />
        {credentials.loading && <Loader />}
        <Box
          my={1}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Link to="#" href="#" onClick={props.onToggle}>
            Forgot password?
          </Link>
          <Button type="submit" variant="contained" onClick={onSubmit}>
            Log In
          </Button>
        </Box>
      </form>
    </Box>
  );
}
