import { TextField } from "@material-ui/core";
import React, { useState } from "react";

export default function SliderValue({
  placeholder,
  onError,
  onComplete,
  defaultValue,
}) {
  const [value, setValue] = useState(defaultValue || "");

  const handleValue = (event) => {
    const result = event.target.value.trim();
    setValue(parseInt(result));
  };

  return (
    <TextField
      variant="filled"
      type="number"
      placeholder={placeholder}
      error={onError}
      helperText={onError && "Please enter a value for this field."}
      onKeyDown={(e) => {
        if (e.key === "Enter") document.activeElement.blur();
      }}
      onChange={handleValue}
      onBlur={() => onComplete(value)}
      value={value}
    />
  );
}
