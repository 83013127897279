//Environment Endpoints
export const DEV_URL = "http://dev.unigigster.com/graphql/";
export const STAGING_URL = "https://staging.unigigster.com/graphql/";
export const PROD_URL = "https://app.unigigster.com/graphql/";

//Local storage keys
export const AUTH_TOKEN = "token";
export const REFRESH_TOKEN = "refreshToken";
export const COOKIE_STATUS = "cookie_status";

//Pages
export const HOME_PAGE = "HOME";
export const CREATE_TASK_PAGE = "CREATE_TASK";
export const TASK_DETAILS_PAGE = "TASK_DETAILS";
export const SUBMISSIONS_PAGE = "SUBMISSIONS";
export const SECTIONS_FORM_PAGE = "SECTION_FORM";
export const VIEW_ALL_SECTIONS_PAGE = "VIEW_ALL_SECTIONS";

//Submission Form Constants
export const FORM_DRAGGABLE_ID = "form-elements-container";
export const FORM_DROPPABLE_ID = "form-capture-container";

export const TEXT_CARD = "text";
export const NUMBER_CARD = "number";
export const CHECK_CARD = "checkbox";
export const RADIO_CARD = "radio";
export const GRID_CARD = "grid";
export const IMAGE_CARD = "image";
export const DATE_TIME_CARD = "datetime";
export const SLIDER_CARD = "slider";
export const URL_BUTTON_CARD = "url_button";

export default function formBuilderLabel(type) {
  switch (type) {
    case TEXT_CARD:
      return "Text";
    case NUMBER_CARD:
      return "Number";
    case RADIO_CARD:
      return "Single Choice";
    case CHECK_CARD:
      return "Multiple Choice";
    case GRID_CARD:
      return "Choice Grid";
    case IMAGE_CARD:
      return "Image";
    case DATE_TIME_CARD:
      return "Date & Time";
    case SLIDER_CARD:
      return "Slider";
    case URL_BUTTON_CARD:
      return "Url Button";
    default:
      break;
  }
}

export const SECTION_CREATION_ERROR =
  "Couldn't create the form at the moment, please try again later.";
