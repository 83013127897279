import React from "react";
import {
  Box,
  Typography,
  CardContent,
  Grid,
  CircularProgress,
} from "@material-ui/core";
import moment from "moment";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import LayersIcon from "@material-ui/icons/Layers";
import BubbleChartIcon from "@material-ui/icons/BubbleChart";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import DonutSmallIcon from "@material-ui/icons/DonutSmall";
import EventIcon from "@material-ui/icons/Event";
import EventBusyIcon from "@material-ui/icons/EventBusy";
import ReactMarkdown from "react-markdown";
import LocationOnIcon from "@material-ui/icons/LocationOn";

export default function TaskDetails({ taskQuery }) {
  const UNIGIGSTER_FEE = 0.25;

  const parseDate = (date) => moment(date).format("Do MMMM YYYY");
  const parseDuration = (time) => {
    const duration = moment.duration(time, "seconds");
    let durationString = "";
    if (duration.days()) {
      const day = duration.days();
      durationString = day === 1 ? day + " day " : day + " days ";
    }
    if (duration.hours()) {
      const hour = duration.hours();
      durationString += hour === 1 ? hour + " hour " : hour + " hours ";
    }
    if (duration.minutes()) {
      const min = duration.minutes();
      durationString += min === 1 ? min + " min" : min + " mins";
    }
    return durationString;
  };
  const getPercent = (taskGroup) => {
    return taskGroup.amount * taskGroup.studentsRequired * UNIGIGSTER_FEE;
  };
  const getTotal = (taskGroup) => {
    return (
      taskGroup.amount * taskGroup.studentsRequired + getPercent(taskGroup)
    );
  };

  const getPill = (icon, title, value) => {
    return (
      <Box className="pill">
        {icon}
        <Box>
          <Typography variant="subtitle1" color="textPrimary">
            {title}
          </Typography>
          <Typography variant="subtitle1" color="textPrimary">
            <Box fontWeight="fontWeightBold">{value}</Box>
          </Typography>
        </Box>
      </Box>
    );
  };

  const paymentTile = (title, subtitle) => {
    return (
      <Box my={1} display="flex" justifyContent="space-between">
        <Typography variant="subtitle1" color="textPrimary">
          {title}
        </Typography>
        <Typography variant="subtitle1" color="textPrimary">
          {subtitle}
        </Typography>
      </Box>
    );
  };

  const leftPane = () => {
    return (
      <Box display="flex" flexWrap="wrap">
        <Box mr={2}>
          {getPill(
            <PeopleAltIcon />,
            "Students required",
            taskQuery.data.taskGroup.studentsRequired
          )}
          {getPill(
            <BubbleChartIcon />,
            "Effort required",
            parseDuration(taskQuery.data.taskGroup.effortRequired)
          )}
          {getPill(
            <DonutSmallIcon />,
            "Category",
            taskQuery.data.taskGroup.tag.name
          )}
          {getPill(
            <EventIcon />,
            "Task start date",
            parseDate(taskQuery.data.taskGroup.allocationStartDate)
          )}
        </Box>
        <Box>
          {getPill(<LayersIcon />, "State", taskQuery.data.taskGroup.state)}
          {getPill(
            <AccessTimeIcon />,
            "Completion window",
            parseDuration(taskQuery.data.taskGroup.completionWindow)
          )}
          {taskQuery.data.taskGroup.tasks[0].properties.postcode &&
            getPill(
              <LocationOnIcon />,
              "Postcode",
              taskQuery.data.taskGroup.tasks[0].properties.postcode
            )}
          {getPill(
            <EventBusyIcon />,
            "Task deadline",
            parseDate(taskQuery.data.taskGroup.listingAllocationEndDate)
          )}
        </Box>
      </Box>
    );
  };

  const rightPane = () => {
    return (
      <Box width="340px">
        <Typography variant="h5" color="textPrimary">
          <Box fontWeight="fontWeightBold">Fees breakdown</Box>
        </Typography>
        {paymentTile(
          "Students required:",
          taskQuery.data.taskGroup.studentsRequired
        )}
        {paymentTile(
          "Amount (Per student):",
          "£ " + taskQuery.data.taskGroup.amount.toFixed(2)
        )}
        {paymentTile(
          "Total amount:",
          "£ " +
            (
              taskQuery.data.taskGroup.amount *
              taskQuery.data.taskGroup.studentsRequired
            ).toFixed(2)
        )}
        {paymentTile(
          "Unigigster fee (25%):",
          "£ " + getPercent(taskQuery.data.taskGroup).toFixed(2)
        )}
        {paymentTile("Taxes:", "N/A")}
        <Box className="total-budget">
          <Typography variant="subtitle1" color="textPrimary">
            Total Budget:
          </Typography>
          <Typography variant="subtitle1" color="textPrimary">
            {"£ " + getTotal(taskQuery.data.taskGroup).toFixed(2)}
          </Typography>
        </Box>
      </Box>
    );
  };

  let content;
  if (taskQuery.loading) {
    content = (
      <Grid container justify="center">
        <CircularProgress />
      </Grid>
    );
  } else if (taskQuery.data) {
    content = (
      <Box>
        <Typography variant="h5" color="textPrimary">
          <Box fontWeight="fontWeightBold">
            {taskQuery.data.taskGroup.title}
          </Box>
        </Typography>
        <ReactMarkdown>{taskQuery.data.taskGroup.description}</ReactMarkdown>
        <Box className="task-particulars">
          <Box>{leftPane}</Box>
          <Box>{rightPane}</Box>
        </Box>
      </Box>
    );
  } else {
    content = (
      <CardContent>
        <Typography align="center">
          No details available on this task.
        </Typography>
      </CardContent>
    );
  }

  return <Box m={2}>{content}</Box>;
}
