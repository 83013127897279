import React from "react";
import { Box, Typography, List } from "@material-ui/core";
import { Droppable } from "react-beautiful-dnd";
import ElementCard from "./ElementCard";
import { FORM_DRAGGABLE_ID } from "../../utils/constants";

export default function Kiosk({ cards }) {
  const draggableZone = (
    <Droppable droppableId={FORM_DRAGGABLE_ID} isDropDisabled={true}>
      {(provided) => (
        <List
          innerRef={provided.innerRef}
          className="draggable-items-container"
        >
          <Box className="draggable-items-container">
            {cards.map((item, idx) => (
              <ElementCard key={item.id} item={item} index={idx} />
            ))}
          </Box>
          {provided.placeholder}
        </List>
      )}
    </Droppable>
  );

  return (
    <Box>
      <Box className="draggable-title">
        <Typography variant="h5" color="textSecondary">
          Form Elements
        </Typography>
      </Box>
      {draggableZone}
    </Box>
  );
}
