import React, { useState } from "react";
import { Box, Typography, Button, CircularProgress } from "@material-ui/core";
import AppDrawer from "../components/AppDrawer";
import TitleIcon from "@material-ui/icons/Title";
import DescriptionIcon from "@material-ui/icons/Description";
import DonutSmallIcon from "@material-ui/icons/DonutSmall";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import BubbleChartIcon from "@material-ui/icons/BubbleChart";
import EventBusyIcon from "@material-ui/icons/EventBusy";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import EventIcon from "@material-ui/icons/Event";
import CurrencyTextField from "../components/task-form/CurrencyTextField";
import { useMutation } from "@apollo/react-hooks";
import LocationField from "../components/task-form/LocationField";
import { CREATE_TASK, EDIT_TASK } from "../utils/mutations";
import moment from "moment";
import { Redirect, useHistory, useParams } from "react-router-dom";
import { CREATE_TASK_PAGE } from "../utils/constants";
import "../styles/global.scss";
import CustomRichTextEditor from "../components/CustomRichTextEditor";
import StartDatePicker from "../components/task-form/StartDatePicker";
import EndDatePicker from "../components/task-form/EndDatePicker";
import CharCountTextField from "../components/CharCountTextfield";
import StudentsCountField from "../components/task-form/StudentsCountField";
import TagsField from "../components/task-form/TagsField";
import DurationPicker from "../components/task-form/DurationPicker";
import CustomTooltip from "../components/CustomToolTip";
import { getRequiredText } from "../utils/utilities";

export default function TaskPage(props) {
  const taskData = props.location.state;

  const { task } = taskData || {};
  const [taskForm, setTaskForm] = useState({
    title: task ? task.title : "",
    titleError: false,
    desc: task ? task.description : "",
    descError: false,
    studentsCount: task ? task.studentsRequired : "",
    studentsCountError: false,
    amount: task ? task.amount : null,
    amountError: false,
    completionDuration: 172800, //Defaulting to 48hours
    tag: task ? task.tag.id : "",
    tagError: false,
    effortDuration: task ? task.effortRequired : "",
    effortError: false,
    location: "",
    locationString: task ? task.tasks[0].properties.postcode : "",
    startDate: task ? task.allocationStartDate : "",
    startDateError: false,
    endDate: task ? task.listingAllocationEndDate : "",
    endDateError: false,
    indefinite: false,
    onSubmissionError: null,
    onSubmissionLoading: false,
  });

  const [submitTask] = useMutation(CREATE_TASK);
  const [updateTask] = useMutation(EDIT_TASK);

  const { id } = useParams();
  const history = useHistory();

  //Navigate to 404 page, incase of unauthorized entry.
  if (id && !taskData) {
    return <Redirect to={{ pathname: "/page-not-found" }} />;
  }
  const currencyHandler = (event, v) => {
    const tempValue = parseFloat(v.toFixed(2));
    if (tempValue && tempValue >= getMinWage()) {
      setAmount(tempValue, false);
    } else {
      setAmount(null, true);
    }
  };

  const onEffortPicked = (duration) => {
    taskForm.effortDuration = duration;
  };

  const getMinWage = () => {
    let rate = 0.15;
    if (taskForm.effortDuration) {
      rate = (taskForm.effortDuration / 60) * rate;
    }
    return rate;
  };

  const setTitle = (title) => {
    setTaskForm((ps) => ({
      ...ps,
      title,
    }));
  };
  const setDesc = (desc) => {
    taskForm.desc = desc;
  };

  const setStudentCount = (count) => {
    setTaskForm((ps) => ({
      ...ps,
      studentsCount: count,
    }));
  };

  const setAmount = (amt, err) => {
    setTaskForm((prevState) => ({
      ...prevState,
      amount: amt,
      amountError: err,
    }));
  };

  const onSelectedTag = (tag) => {
    taskForm.tag = tag;
  };

  const onLocationReceived = (coords) => {
    taskForm.location = coords;
  };

  const onSubmit = () => {
    if (validate()) {
      setTaskForm((prevState) => ({
        ...prevState,
        onSubmissionLoading: true,
      }));
      submitFormData();
    }
  };

  const validate = () => {
    const {
      title,
      desc,
      studentsCount,
      amount,
      effortDuration,
      tag,
      startDate,
      endDate,
    } = taskForm;
    let titleStatus = title.trim().length === 0;
    let descStatus = desc.trim().length === 0;
    let studentCountStatus = studentsCount === "";
    let effortStatus = effortDuration < 900;
    let amountStatus = amount === null || amount < getMinWage();
    let tagStatus = tag === "";
    let startDateStatus = startDate === "";
    let endDateStatus;
    if (startDateStatus || endDate === "") {
      endDateStatus = true;
    } else if (endDate.valueOf() <= startDate.valueOf()) {
      endDateStatus = true;
    } else {
      endDateStatus = false;
    }
    setTaskForm((prevState) => ({
      ...prevState,
      titleError: titleStatus,
      descError: descStatus,
      studentsCountError: studentCountStatus,
      amountError: amountStatus,
      effortError: effortStatus,
      tagError: tagStatus,
      startDateError: startDateStatus,
      endDateError: endDateStatus,
    }));
    return (
      !titleStatus &&
      !descStatus &&
      !studentCountStatus &&
      !amountStatus &&
      !effortStatus &&
      !tagStatus &&
      !startDateStatus &&
      !endDateStatus
    );
  };

  const onSuccess = (result) => {
    setTaskForm((prevState) => ({
      ...prevState,
      onSubmissionLoading: false,
    }));
    const { data } = result;

    if (taskData && data.editTaskGroup.success) {
      history.goBack();
    } else {
      const resultId = data.createTask.taskGroup.id;
      const path = `/tasks/${resultId}/sections`;
      history.replace(path);
    }
  };

  const onError = (error) => {
    const errMsg = error.message;
    setTaskForm((prevState) => ({
      ...prevState,
      onSubmissionError: errMsg,
      onSubmissionLoading: false,
    }));
  };

  const submitFormData = () => {
    let submissionData;
    if (taskForm.location) {
      submissionData = {
        title: taskForm.title,
        description: taskForm.desc,
        tag: taskForm.tag,
        postcode: taskForm.location.postcode,
        location: {
          latitude: taskForm.location.latitude,
          longitude: taskForm.location.longitude,
        },
        studentsRequired: taskForm.studentsCount,
        amount: taskForm.amount.toFixed(2),
        completionWindow: taskForm.completionDuration,
        effortRequired: taskForm.effortDuration,
        listingStartDate: moment(taskForm.startDate).format("YYYY-MM-DD"),
        listingAllocationEndDate: moment(taskForm.endDate).format("YYYY-MM-DD"),
      };
    } else {
      submissionData = {
        title: taskForm.title,
        description: taskForm.desc,
        tag: taskForm.tag,
        studentsRequired: taskForm.studentsCount,
        amount: taskForm.amount.toFixed(2),
        completionWindow: taskForm.completionDuration,
        effortRequired: taskForm.effortDuration,
        listingStartDate: moment(taskForm.startDate).format("YYYY-MM-DD"),
        listingAllocationEndDate: moment(taskForm.endDate).format("YYYY-MM-DD"),
      };
    }
    if (task) {
      updateTask({
        variables: {
          data: { taskGroup: task.id, ...submissionData },
        },
      })
        .then(onSuccess)
        .catch(onError);
    } else {
      submitTask({
        variables: {
          data: submissionData,
        },
      })
        .then(onSuccess)
        .catch(onError);
    }
  };

  return (
    <AppDrawer pageName={CREATE_TASK_PAGE}>
      <Box mt={1}>
        <Box my={2} className="page-bar">
          <Typography variant="h5" color="textSecondary">
            {taskData ? "Edit task" : "Create new task"}
          </Typography>
        </Box>
        <Box mx={2}>
          {getRequiredText(
            "Required fields are marked with an asterisk",
            "subtitle1"
          )}
        </Box>
        <Box>
          <Box className="field-container">
            <Box className="label">
              <TitleIcon />
              {getRequiredText("Title", "h6")}
            </Box>
            <CharCountTextField
              charLimit={200}
              label="Please enter a title for your task"
              onComplete={setTitle}
              onError={taskForm.titleError}
              shouldFocus={true}
              defaultValue={taskForm.title}
            />
          </Box>
          <Box className="field-container">
            <Box className="label">
              <DescriptionIcon />
              {getRequiredText("Description", "h6")}
            </Box>
            <Box width="100%">
              <CustomRichTextEditor
                label="Please describe more about your task here..."
                onComplete={(content) => {
                  const value = content.trim();
                  setDesc(value);
                }}
                defaultValue={taskForm.desc}
              />
              {taskForm.descError && (
                <Typography variant="subtitle2" color="error">
                  Please enter the description for the section.
                </Typography>
              )}
            </Box>
          </Box>
          <Box className="field-container" flexWrap="wrap">
            <Box className="pair-fields">
              <Box className="label">
                <DonutSmallIcon />
                <Box className="tooltip-label">
                  {getRequiredText("Category", "h6")}
                  <CustomTooltip content="Lorem ipsum dorem illuta" />
                </Box>
              </Box>
              <TagsField
                onChange={onSelectedTag}
                showError={taskForm.tagError}
                defaultValue={taskForm.tag}
              />
            </Box>
            <Box className="pair-fields">
              <Box className="label">
                <PeopleAltIcon />
                <Box className="tooltip-label">
                  {getRequiredText("Students required", "h6")}
                  <CustomTooltip content="Lorem ipsum dorem illuta" />
                </Box>
              </Box>
              <StudentsCountField
                onCountChange={(e) => setStudentCount(e.target.value)}
                countError={taskForm.studentsCountError}
                defaultValue={taskForm.studentsCount}
              />
            </Box>
          </Box>

          <Box className="field-container" flexWrap="wrap">
            <Box className="pair-fields">
              <Box className="label">
                <span id="amount-icon">£</span>
                <Box className="tooltip-label">
                  {getRequiredText("Amount", "h6")}
                  <CustomTooltip content="Lorem ipsum dorem illuta" />
                </Box>
              </Box>
              <Box width="225px">
                <CurrencyTextField
                  variant="filled"
                  currencySymbol="£"
                  name="amount"
                  value={taskForm.amount}
                  onBlur={currencyHandler}
                  error={taskForm.amountError}
                  helperText={
                    taskForm.amountError &&
                    "Enter a minimum of £ " + getMinWage()
                  }
                  minimumValue="1"
                />
              </Box>
            </Box>
            <Box className="pair-fields">
              <Box className="label">
                <BubbleChartIcon />
                <Box className="tooltip-label">
                  {getRequiredText("Effort required", "h6")}
                  <CustomTooltip content="Enter a duration in HH:MM format." />
                </Box>
              </Box>
              <DurationPicker
                showError={taskForm.effortError}
                onComplete={onEffortPicked}
                defaultValue={taskForm.effortDuration}
              />
            </Box>
          </Box>

          <Box className="field-container" flexWrap="wrap">
            <Box className="pair-fields">
              <Box className="label">
                <EventIcon />
                <Box className="tooltip-label">
                  {getRequiredText("Task start date", "h6")}
                  <CustomTooltip content="Lorem ipsum dorem illuta" />
                </Box>
              </Box>
              <Box className="date-pickers">
                <StartDatePicker
                  showError={taskForm.startDateError}
                  onChange={(choosenDate) => (taskForm.startDate = choosenDate)}
                  defaultValue={taskForm.startDate}
                />
                {taskForm.startDateError && (
                  <Typography variant="caption" color="error">
                    Confirm task start date.
                  </Typography>
                )}
              </Box>
            </Box>
            <Box className="pair-fields">
              <Box className="label">
                <EventBusyIcon />
                <Box className="tooltip-label">
                  {getRequiredText("Task deadline", "h6")}
                  <CustomTooltip content="Lorem ipsum dorem illuta" />
                </Box>
              </Box>
              <Box className="date-pickers">
                <EndDatePicker
                  showError={taskForm.endDateError}
                  onChange={(value) => (taskForm.endDate = value)}
                  defaultValue={taskForm.endDate}
                />
                {taskForm.endDateError && (
                  <Typography variant="caption" color="error">
                    Confirm task deadline.
                  </Typography>
                )}
              </Box>
            </Box>
          </Box>

          <Box className="field-container" flexWrap="wrap">
            <Box className="pair-fields">
              <Box className="label">
                <LocationOnIcon />
                <Box className="tooltip-label">
                  <Typography color="textPrimary" variant="h6">
                    Postcode
                  </Typography>
                  <CustomTooltip content="Lorem ipsum dorem illuta" />
                </Box>
              </Box>
              <LocationField
                defaultValue={taskForm.locationString}
                onChange={onLocationReceived}
                onValidationError={taskForm.locationError}
              />
            </Box>
          </Box>

          <Button variant="contained" color="primary" onClick={onSubmit}>
            {taskForm.onSubmissionLoading ? (
              <CircularProgress color="secondary" />
            ) : taskData ? (
              "Update"
            ) : (
              "Submit"
            )}
          </Button>
          {taskForm.onSubmissionError && (
            <Typography
              style={{ marginTop: "8px" }}
              variant="subtitle2"
              color="error"
            >
              {taskForm.onSubmissionError}
            </Typography>
          )}
        </Box>
      </Box>
    </AppDrawer>
  );
}
