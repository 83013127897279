import React, { useState } from "react";

import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { InputAdornment, IconButton } from "@material-ui/core";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";

function StartDatePicker({ defaultValue, onChange, showError }) {
  var currentDate = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    new Date().getDate()
  );

  const [startDate, setStartDate] = useState(
    defaultValue ? new Date(defaultValue) : currentDate
  );

  const onStartDateSelected = (selectedDate) => {
    if (selectedDate) {
      const selectedDay = selectedDate.valueOf();
      const currentDay = currentDate.valueOf();
      if (selectedDay === currentDay || selectedDay > currentDay) {
        setStartDate(selectedDate);
        onChange(selectedDate);
      }
    }
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        style={{ width: "220px" }}
        inputVariant="filled"
        variant="filled"
        format="dd/MM/yyyy"
        margin="normal"
        disablePast
        autoOk
        onChange={onStartDateSelected}
        value={startDate}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton>
                <CalendarTodayIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        error={showError}
      />
    </MuiPickersUtilsProvider>
  );
}

export default StartDatePicker;
