import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./components/App.js";
import * as serviceWorker from "./serviceWorker";
import { InMemoryCache } from "apollo-boost";
import { ApolloClient } from "apollo-client";
import { ApolloProvider } from "@apollo/react-hooks";
import { setContext } from "apollo-link-context";
import { AUTH_TOKEN, STAGING_URL, DEV_URL, PROD_URL } from "./utils/constants";
import { CssBaseline } from "@material-ui/core";
import { createUploadLink } from "apollo-upload-client";

const getEndpoint = () => {
  if (process.env.REACT_APP_PRODUCTION) return PROD_URL;
  else if (process.env.REACT_APP_STAGING) return STAGING_URL;
  else return DEV_URL;
};

const httpLink = createUploadLink({
  uri: getEndpoint(),
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem(AUTH_TOKEN);

  //TODO: Replace the unprotected header check with better implementation.
  if (headers && headers.unprotected) {
    delete headers["unprotected"];
    return {
      headers: {
        ...headers,
      },
    };
  }
  return {
    headers: {
      ...headers,
      authorization: token ? `JWT ${token}` : "",
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <CssBaseline />
      <App />
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

serviceWorker.unregister();
