import React from "react";
import { Icon } from "@material-ui/core";
import FAQImage from "../images/icons/faq_icon.png";
import {
  TEXT_CARD,
  NUMBER_CARD,
  RADIO_CARD,
  CHECK_CARD,
  IMAGE_CARD,
  GRID_CARD,
  SLIDER_CARD,
  DATE_TIME_CARD,
  URL_BUTTON_CARD,
} from "./constants";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import FontDownloadIcon from "@material-ui/icons/FontDownload";
import LooksOneIcon from "@material-ui/icons/LooksOne";
import GridOnIcon from "@material-ui/icons/GridOn";
import ImageIcon from "@material-ui/icons/Image";
import DateRangeIcon from "@material-ui/icons/DateRange";
import LinearScaleIcon from "@material-ui/icons/LinearScale";
import FormatColorTextIcon from "@material-ui/icons/FormatColorText";

export const FAQIcon = () => (
  <Icon>
    <img src={FAQImage} alt="FAQ navigation icon." width="auto" height="24px" />
  </Icon>
);

export default function getFormbuilderIcon(type) {
  switch (type) {
    case TEXT_CARD:
      return <FontDownloadIcon />;
    case NUMBER_CARD:
      return <LooksOneIcon />;
    case RADIO_CARD:
      return <RadioButtonCheckedIcon />;
    case CHECK_CARD:
      return <CheckBoxIcon />;
    case GRID_CARD:
      return <GridOnIcon />;
    case IMAGE_CARD:
      return <ImageIcon />;
    case DATE_TIME_CARD:
      return <DateRangeIcon />;
    case SLIDER_CARD:
      return <LinearScaleIcon />;
    case URL_BUTTON_CARD:
      return <FormatColorTextIcon />;
    default:
      break;
  }
}
