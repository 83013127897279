import React, { useState, useContext } from "react";
import CancelIcon from "@material-ui/icons/Cancel";
import {
  Box,
  makeStyles,
  Typography,
  Button,
  CircularProgress,
} from "@material-ui/core";
import { useMutation } from "@apollo/react-hooks";
import { CHECKOUT_SESSION } from "../../utils/mutations";
import { useParams } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import { commonStyles } from "../../styles/material-styles";
import { AppContext } from "../../contexts/app_context";

const useStyles = makeStyles((theme) => ({
  buttonStyle: {
    "&:hover": {
      color: "black",
    },
    backgroundColor: theme.palette.error.main,
    color: "white",
    borderRadius: 40,
    textTransform: "none",
    fontSize: 18,
  },
  progressColor: {
    color: "white",
  },
}));

export default function FailurePage() {
  const custom = useStyles();
  const common = commonStyles();
  const { id } = useParams();

  const [checkoutState, setCheckoutState] = useState({
    loading: false,
    error: false,
  });

  const appState = useContext(AppContext);

  const [getCheckoutSession] = useMutation(CHECKOUT_SESSION);

  const retryCheckout = async (event) => {
    setCheckoutState({
      error: false,
      loading: true,
    });
    getCheckoutSession({ variables: { taskGroup: id } })
      .then(async (res) => {
        const sessionId = res.data.checkoutSession.sessionId;
        const stripe = await loadStripe(appState.keys.stripe);
        await stripe
          .redirectToCheckout({
            sessionId,
          })
          .catch((error) => {
            console.log("ON REDIRECT ERROR" + error);
            setCheckoutState((prevState) => ({
              ...prevState,
              error: true,
            }));
          });
        setCheckoutState((prevState) => ({
          ...prevState,
          loading: false,
        }));
      })
      .catch((err) => {
        console.log("ON Error: " + err);
        setCheckoutState({
          error: true,
          loading: false,
        });
      });
  };

  return (
    <Box className={common.centerContainer}>
      <CancelIcon className={common.failureIconStyle} />

      <Typography
        style={{ fontWeight: "bold" }}
        color="textPrimary"
        variant="h3"
      >
        Payment Failed!
      </Typography>
      <Typography className={common.descStyle} color="textPrimary" variant="h6">
        We couldn't verify your payment. If the amount was deducted, please
        contact our support team.
      </Typography>
      {checkoutState.loading ? (
        <Button className={custom.buttonStyle} variant="contained">
          <CircularProgress className={custom.progressColor} />
        </Button>
      ) : (
        <Button
          className={custom.buttonStyle}
          variant="contained"
          onClick={retryCheckout}
        >
          Retry Payment
        </Button>
      )}
      {checkoutState.error && (
        <Typography variant="subtitle1" color="error">
          Checkout process failed, please try again later.
        </Typography>
      )}
    </Box>
  );
}
