import React, { useState } from "react";
import {
  Card,
  CardContent,
  List,
  Checkbox,
  Divider,
  Box,
  Grid,
  CircularProgress,
  Typography,
} from "@material-ui/core";
import TaskItem from "./TaskItem";
import { useQuery } from "@apollo/react-hooks";
import { GET_TASK_GROUPS } from "./../../utils/queries";

function TasksList() {
  const [checkAll, setCheckAll] = useState(false);
  const { loading, data } = useQuery(GET_TASK_GROUPS, {
    fetchPolicy: "no-cache",
  });

  const onCheckAll = () => {
    setCheckAll(!checkAll);
  };

  let content;
  if (loading) {
    content = (
      <CardContent>
        <Grid container justify="center">
          <CircularProgress />
        </Grid>
      </CardContent>
    );
  } else {
    if (data && data.allTaskGroups.length) {
      content = (
        <CardContent className="task-card-content">
          <Box my={1} onClick={onCheckAll} className="select-all-box">
            <Checkbox onClick={onCheckAll} checked={checkAll} color="primary" />
            <Typography variant="subtitle1">Select all</Typography>
          </Box>
          <Divider />
          <List>
            {data.allTaskGroups.map((taskGrp, index) => {
              return (
                <TaskItem
                  key={taskGrp.id}
                  index={index}
                  taskGroup={taskGrp}
                  onCheckAll={checkAll}
                />
              );
            })}
          </List>
        </CardContent>
      );
    } else {
      content = (
        <CardContent>
          <Typography align="center">No tasks available.</Typography>
        </CardContent>
      );
    }
  }

  return <Card>{content}</Card>;
}

export default TasksList;
