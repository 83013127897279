import React, { useState } from "react";
import web_logo from "../images/web_logo.png";
import { Box, makeStyles } from "@material-ui/core";
import login_bg from "../images/login_bg.png";
import Login from "../components/login/Login";
import { YELLOW_BG, LOGIN_CONTAINER_BG } from "../utils/colors";
import ForgotPassword from "../components/login/ForgotPassword";
import AcceptCookie from "../components/AcceptCookie";
import { useHistory } from "react-router-dom";
import { AppConsumer } from "../contexts/app_context";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: YELLOW_BG,
    backgroundImage: `url(${login_bg})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
  },
  logo: {
    display: "block",
    margin: "auto",
    height: 90,
  },
}));

export default function LoginPage(props) {
  const classes = useStyles();

  const [resetPassword, setResetPassword] = useState(false);

  const history = useHistory();
  const { from } = props.location.state || {
    from: { pathname: "/" },
  };

  const togglePasswordReset = (e) => {
    e.preventDefault();
    setResetPassword(!resetPassword);
  };

  return (
    <AppConsumer>
      {(contextValue) => {
        if (contextValue.user) {
          history.replace(from);
          return;
        }
        return (
          <Box>
            <Box className={classes.container}>
              <Box
                width={460}
                bgcolor={LOGIN_CONTAINER_BG}
                p={2}
                borderRadius={16}
              >
                <Box>
                  <img className={classes.logo} src={web_logo} alt="Logo" />
                </Box>
                {resetPassword ? (
                  <ForgotPassword onToggle={togglePasswordReset} />
                ) : (
                  <Login
                    onToggle={togglePasswordReset}
                    loc={from}
                    contextState={contextValue}
                  />
                )}
              </Box>
            </Box>
            <AcceptCookie />
          </Box>
        );
      }}
    </AppConsumer>
  );
}
