import React from "react";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { Tooltip } from "@material-ui/core";

export default function CustomTooltip({ content }) {
  return (
    <Tooltip placement="top" title={content}>
      <InfoOutlinedIcon id="tooltip-icon" />
    </Tooltip>
  );
}
