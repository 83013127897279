import React, { Component } from "react";
import PageController from "./../pages/PageController";
import { ThemeProvider } from "@material-ui/core";
import theme from "../contexts/theme";

class App extends Component {
  render() {
    return (
      <ThemeProvider theme={theme}>
        <PageController />
      </ThemeProvider>
    );
  }
}

export default App;
