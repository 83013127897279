import React, { useState, Fragment, useEffect, useContext } from "react";
import {
  ListItem,
  Checkbox,
  Typography,
  IconButton,
  Divider,
  Tooltip,
  CircularProgress,
  Box,
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { useHistory, useParams } from "react-router-dom";
import { useMutation } from "@apollo/react-hooks";
import { CREATE_SUBMISSION_FORM, DELETE_SECTION } from "../../utils/mutations";
import { AppContext } from "../../contexts/app_context";

export default function SubmissionItem({
  onCheckAll,
  submissionData,
  onRefetch,
}) {
  const [check, setCheck] = useState(false);
  const [cloning, setCloning] = useState(false);
  const [promptDelete, setDeletePrompt] = useState(false);
  const history = useHistory();
  const { id } = useParams();
  const [createSubmissionForm] = useMutation(CREATE_SUBMISSION_FORM);
  const [deleteSection] = useMutation(DELETE_SECTION);
  const appState = useContext(AppContext);

  const handleCheck = () => {
    setCheck(!check);
  };

  useEffect(() => {
    setCheck(onCheckAll);
  }, [onCheckAll]);

  const onDelete = () => {
    deleteSection({
      variables: { submission: submissionData.id },
    }).then((result) => {
      if (result.data.deleteSubmission.success) {
        setDeletePrompt(false);
        onRefetch();
      }
    });
  };

  const onEdit = () => {
    history.push({
      pathname: "/tasks/" + id + "/sections/form",
      state: {
        section: submissionData,
      },
    });
  };

  const cloneSection = () => {
    setCloning(true);
    const cloneData = {
      taskGroup: id,
      title: submissionData.title,
      description: submissionData.description,
      checkin: submissionData.checkin,
      template: submissionData.template,
    };
    createSubmissionForm({
      variables: { data: cloneData },
    }).then((result) => {
      setCloning(false);
      onRefetch();
    });
  };

  const deleteDialog = () => {
    return (
      <Dialog
        open={promptDelete}
        onClose={() => {
          setDeletePrompt(false);
        }}
      >
        <DialogTitle>
          <Typography variant="h6" color="textPrimary">
            Are you sure you want to delete this section?
          </Typography>
        </DialogTitle>
        <DialogActions>
          <Button
            onClick={() => {
              setDeletePrompt(false);
            }}
            style={{ color: "red" }}
          >
            Cancel
          </Button>
          <Button onClick={onDelete} color="primary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <Fragment>
      <ListItem className="section-item" button>
        <Box className="section-title">
          <Checkbox
            onClick={handleCheck}
            checked={check}
            color="primary"
            edge="start"
          />
          <Typography variant="h6" color="textPrimary">
            {submissionData.title}
          </Typography>
        </Box>
        {appState.shouldShowSectionActions && (
          <Box className="section-actions">
            {cloning ? (
              <CircularProgress />
            ) : (
              <Tooltip title="Clone">
                <IconButton onClick={cloneSection} edge="end">
                  <FileCopyIcon />
                </IconButton>
              </Tooltip>
            )}
            <IconButton onClick={onEdit} edge="end">
              <EditIcon className="edit-btn" />
            </IconButton>
            <IconButton
              onClick={() => {
                setDeletePrompt(true);
              }}
              edge="end"
            >
              <DeleteIcon color="error" />
            </IconButton>
            {deleteDialog()}
          </Box>
        )}
      </ListItem>
      <Divider />
    </Fragment>
  );
}
