import React, { useState } from "react";
import {
  makeStyles,
  InputAdornment,
  IconButton,
  Box,
  CircularProgress,
  FilledInput,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

const useStyles = makeStyles((theme) => ({
  customErrorStyle: {
    margin: "auto",
    color: "red",
  },
  customVerifiedStyle: {
    margin: "auto",
    color: theme.palette.primary.main,
  },
}));
function LocationField({ onChange, defaultValue }) {
  const classes = useStyles();

  let verify = false;

  const [postCode, setPostCode] = useState({
    code: defaultValue,
    postcodeError: false,
    APIError: false,
    loading: false,
    verified: false,
  });

  const API_CALL = "https://api.postcodes.io/postcodes/";

  const onIconClick = () => {
    verify = postCode.code !== "";
    if (verify) {
      updateAPIState(true);
      let QUERY = postCode.code;
      fetch(API_CALL + QUERY)
        .then((res) => res.json())
        .then((data) => {
          let apiSuccess;

          if (data.result) {
            apiSuccess = true;
            onChange({
              latitude: data.result.latitude,
              longitude: data.result.longitude,
              postcode: data.result.postcode,
            });
          } else {
            updateError(true, true);
          }

          updateAPIState(false, apiSuccess);
        });
    } else {
      //RESET
      updateError(false);
      updateAPIState(false, false);
      onChange(null);
    }
  };

  const updateError = (status, responseError) => {
    setPostCode((prevState) => ({
      ...prevState,
      postcodeError: status,
      APIError: responseError,
    }));
  };
  const updateAPIState = (status, apiVerified) => {
    setPostCode((prevState) => ({
      ...prevState,
      loading: status,
      verified: apiVerified,
    }));
  };

  const handleChange = (event) => {
    const value = event.target.value;
    setPostCode((prevState) => ({
      ...prevState,
      code: value,
    }));
  };

  let errorElement;
  if (postCode.verified) {
    errorElement = (
      <p className={classes.customVerifiedStyle}>Postcode verified.</p>
    );
  } else if (postCode.postcodeError && postCode.APIError) {
    errorElement = (
      <p className={classes.customErrorStyle}>Invalid postcode.</p>
    );
  } else {
    errorElement = null;
  }

  return (
    <Box width="220px">
      <FilledInput
        value={postCode.code}
        onChange={handleChange}
        placeholder="Enter postcode"
        onBlur={onIconClick}
        endAdornment={
          <InputAdornment className="postcode-adornament">
            {postCode.loading ? (
              <CircularProgress />
            ) : (
              <IconButton onClick={onIconClick} edge="end">
                {postCode.verified ? <CheckCircleIcon /> : <SearchIcon />}
              </IconButton>
            )}
          </InputAdornment>
        }
      />
      {errorElement}
    </Box>
  );
}

export default LocationField;
