import React from "react";
import { Box, Typography } from "@material-ui/core";
import { Droppable } from "react-beautiful-dnd";
import { FORM_DROPPABLE_ID } from "../../utils/constants";
import EnvelopCard from "./EnvelopCard";

export default function ElementDroppables(props) {
  const itemsAvailable = props.elements.length;
  return (
    <Box>
      <Droppable droppableId={FORM_DROPPABLE_ID}>
        {(provided) => (
          <ul
            className={itemsAvailable ? "filled-drop-list" : "empty-drop-list"}
            ref={provided.innerRef}
          >
            {props.elements.length
              ? props.elements.map((item, index) => (
                  <EnvelopCard key={item.id} item={item} index={index} />
                ))
              : provided.placeholder && (
                  <Typography
                    id="drop-here-hint"
                    variant="h6"
                    color="textPrimary"
                  >
                    Drop items here...
                  </Typography>
                )}
            {provided.placeholder}
          </ul>
        )}
      </Droppable>
    </Box>
  );
}
