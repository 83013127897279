import React from "react";
import { Box, Typography } from "@material-ui/core";
import { commonStyles } from "../styles/material-styles";
import SentimentVeryDissatisfiedIcon from "@material-ui/icons/SentimentVeryDissatisfied";

export default function NotFoundPage() {
  const common = commonStyles();

  return (
    <Box className={common.centerContainer}>
      <SentimentVeryDissatisfiedIcon className={common.failureIconStyle} />
      <Typography
        style={{ fontWeight: "bold" }}
        color="textPrimary"
        variant="h3"
        align="center"
      >
        404 - Page not found!
      </Typography>
    </Box>
  );
}
