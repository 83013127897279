import React, { useContext, useState } from "react";
import { Box, Typography, Button } from "@material-ui/core";
import { FormBuilderContext } from "../../../contexts/app_context";
import {
  getQuestionTextfield,
  getRequiredText,
  getValuesField,
} from "../../../utils/utilities";
import getFormbuilderIcon from "../../../utils/custom-icons";

export default function GridTypeField({ element }) {
  const itemContext = useContext(FormBuilderContext);
  const { onResult } = element;
  const [fields, setFields] = useState({
    label: onResult ? onResult.label : "",
    labelError: element.onError,
    shouldAddRow: false,
    shouldAddCol: false,
  });

  const [rowValues, setRowValues] = useState(
    onResult ? onResult.rowValues : []
  );
  const [colValues, setColValues] = useState(
    onResult ? onResult.colValues : []
  );

  const setLabel = (event) => {
    const value = event.target.value.trim();

    setFields((prevState) => ({
      ...prevState,
      label: value,
      labelError: value ? false : true,
    }));
    submitJson(value);
  };

  const setRowValue = (data) => {
    const index = data.index > rowValues.length ? rowValues.length : data.index;
    let hold = rowValues;
    hold[index] = data.value;
    setRowValues([...hold]);
    setShouldAdd(false, "row");
    submitJson(fields.label);
  };

  const removeRowValue = (pos) => {
    setRowValues((prevState) => {
      prevState.splice(pos, 1);
      return [...prevState];
    });
    submitJson(fields.label);
  };

  const setColValue = (data) => {
    const index = data.index > colValues.length ? colValues.length : data.index;
    let hold = colValues;
    hold[index] = data.value;
    setColValues([...hold]);
    setShouldAdd(false, "col");
    submitJson(fields.label);
  };

  const removeColValue = (pos) => {
    setColValues((prevState) => {
      prevState.splice(pos, 1);
      return [...prevState];
    });
    submitJson(fields.label);
  };

  const setShouldAdd = (value, type) => {
    type === "row"
      ? setFields((ps) => ({ ...ps, shouldAddRow: value }))
      : setFields((ps) => ({ ...ps, shouldAddCol: value }));
  };

  const addRowOption = () => {
    if (rowValues.length >= 2 && rowValues.length < 10) {
      setShouldAdd(true, "row");
    }
  };

  const addColOption = () => {
    if (colValues.length >= 2 && colValues.length < 10) {
      setShouldAdd(true, "col");
    }
  };

  const submitJson = (question) => {
    if (question && rowValues.length && colValues.length) {
      itemContext.updateItem({
        item: element,
        data: {
          type: element.type,
          label: question,
          response: null,
          selection: "single",
          rowValues: rowValues,
          colValues: colValues,
        },
      });
    } else {
      itemContext.updateItem({
        item: element,
        data: null,
      });
    }
  };

  return (
    <Box className="question-container">
      <Box className="item-icon-style">{getFormbuilderIcon(element.type)}</Box>
      <Box className="question-fields">
        <Box>
          {getRequiredText("Enter your question", "h6")}
          {getQuestionTextfield(
            fields.label,
            fields.labelError,
            setLabel,
            "Please describe the question for the choice grid here..."
          )}
        </Box>
        <Box className="options-container">
          <Box className="grid-container">
            <Box className="row-options">
              <Typography variant="h5" color="textPrimary">
                Rows
              </Typography>
              {getValuesField(
                rowValues,
                setRowValue,
                removeRowValue,
                element,
                fields.shouldAddRow
              )}
              <Button onClick={addRowOption}>Add row</Button>
            </Box>
            <Box className="column-options">
              <Typography variant="h5" color="textPrimary">
                Columns
              </Typography>
              {getValuesField(
                colValues,
                setColValue,
                removeColValue,
                element,
                fields.shouldAddCol
              )}
              <Button onClick={addColOption}>Add column</Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
