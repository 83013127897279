import React from "react";
import LoginPage from "./LoginPage";
import { useQuery } from "@apollo/react-hooks";
import { GET_CURRENT_USER } from "../utils/queries";
import {
  AppProvider,
  contextState,
  AppConsumer,
} from "../contexts/app_context";
import HomePage from "./HomePage";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import TaskPage from "./TaskPage";
import { Box, CircularProgress } from "@material-ui/core";
import SubmissionsPage from "./SubmissionsPage";
import SuccessPage from "./stripe/SuccessPage";
import FailurePage from "./stripe/FailurePage";
import EmailVerification from "./EmailVerification";
import PasswordReset from "./PasswordReset";
import PrivacyPolicy from "./PrivacyPolicy";
import TermsConditions from "./TermsConditions";
import NotFoundPage from "./NotFoundPage";
import { commonStyles } from "../styles/material-styles";
import SectionBuilderPage from "./SectionBuilderPage";
import ViewAllSections from "./ViewAllSections";

export default function PageController() {
  const common = commonStyles();

  let page;

  const { data, loading } = useQuery(GET_CURRENT_USER);

  const handleUserData = (userData) => {
    const { me } = userData;
    const userDetails = {
      id: me.id,
      firstName: me.firstName,
      stripeId: me.stripeId,
    };
    contextState.setUser(userDetails);
  };

  if (data) {
    handleUserData(data);
  }

  const ProtectedRoute = ({ component: Component, ...rest }) => (
    <AppConsumer>
      {(contextValue) => (
        <Route
          {...rest}
          render={(props) =>
            contextValue.user ? (
              <Component {...props} />
            ) : (
              <Redirect
                to={{
                  pathname: "/login",
                  state: { from: props.location },
                }}
              />
            )
          }
        />
      )}
    </AppConsumer>
  );

  if (loading) {
    page = (
      <Box className={common.centerContainer}>
        <CircularProgress />
      </Box>
    );
  } else {
    page = (
      <AppProvider value={contextState}>
        <Router>
          <Switch>
            <ProtectedRoute path="/" exact component={HomePage} />
            <Route path="/login" exact component={LoginPage} />
            {/* Once the dashboard is set, navigate user from homepage to task page. */}
            <ProtectedRoute path="/tasks/new" exact component={TaskPage} />
            {/* Navigating to task create page on the event of task modification.  */}
            <ProtectedRoute path="/tasks/:id" exact component={TaskPage} />
            <ProtectedRoute
              path="/tasks/:id/sections"
              exact
              component={SubmissionsPage}
            />
            <ProtectedRoute
              path="/tasks/:id/sections/form"
              exact
              component={SectionBuilderPage}
            />
            <ProtectedRoute
              path="/tasks/:id/sections/all"
              exact
              component={ViewAllSections}
            />
            <Route
              path="/tasks/:id/payment/success"
              exact
              component={SuccessPage}
            />
            <Route
              path="/tasks/:id/payment/failure"
              exact
              component={FailurePage}
            />
            <Route path="/email" exact component={EmailVerification} />
            <Route path="/password" exact component={PasswordReset} />
            <Route path="/privacy-policy" exact component={PrivacyPolicy} />
            <Route
              path="/terms-and-conditions"
              exact
              component={TermsConditions}
            />
            <Route component={NotFoundPage} />
          </Switch>
        </Router>
      </AppProvider>
    );
  }
  return page;
}
