import React, { useState, useEffect } from "react";
import { commonStyles } from "../styles/material-styles";
import { Box, Typography, CircularProgress } from "@material-ui/core";
import { useMutation } from "@apollo/react-hooks";
import { EMAIL_VERIFICATION } from "../utils/mutations";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import { Redirect } from "react-router-dom";

export default function EmailVerification(props) {
  const common = commonStyles();

  const [verifyEmail] = useMutation(EMAIL_VERIFICATION);

  const [state, setState] = useState({ loading: true, verified: false });

  const url = new URL(window.location.href);
  const token = url.searchParams.get("verify");

  const updateState = (value) => {
    setState(() => ({
      loading: false,
      verified: value,
    }));
  };

  useEffect(() => {
    if (state.loading && token) {
      verifyEmail({ variables: { token: token } })
        .then((result) => {
          let status = false;
          if (result.data.emailVerification.success) {
            status = true;
          }
          updateState(status);
        })
        .catch((error) => {
          if (error.message === "GraphQL error: Email already verified.") {
            updateState(true);
          } else {
            updateState(false);
          }
        });
    }
  });
  if (!token) {
    return <Redirect to={{ pathname: "/page-not-found" }} />;
  } else if (state.loading) {
    return (
      <Box className={common.centerContainer}>
        <CircularProgress />
      </Box>
    );
  } else {
    return (
      <Box className={common.centerContainer}>
        {state.verified ? (
          <CheckCircleIcon className={common.successIconStyle} />
        ) : (
          <CancelIcon className={common.failureIconStyle} />
        )}
        <Typography
          style={{ fontWeight: "bold" }}
          color="textPrimary"
          variant="h3"
          align="center"
        >
          {state.verified
            ? "Email Verification Successful"
            : "Email Verification Failed"}
        </Typography>
        <Typography
          className={common.descStyle}
          color="textPrimary"
          variant="h6"
          align="center"
        >
          {state.verified
            ? "Your email has been verified successfully."
            : "We couldn't verify your email at the moment."}
        </Typography>
      </Box>
    );
  }
}
