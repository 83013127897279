import React, { useState, useContext } from "react";
import { useParams, Link, useHistory, Redirect } from "react-router-dom";
import AppDrawer from "../components/AppDrawer";
import { Button, Box, CircularProgress, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import SaveAltIcon from "@material-ui/icons/SaveAlt";
import SubmissionsList from "../components/submissions-list/SubmissionsList";
import { SUBMISSIONS_PAGE } from "../utils/constants";
import { useQuery, useMutation } from "@apollo/react-hooks";
import {
  BUDGET_SUMMARY,
  GET_SUBMISSIONS_LIST,
  GET_TASK_GROUP,
  RESPONSE_SUMMARY,
} from "../utils/queries";
import { loadStripe } from "@stripe/stripe-js";
import { CHECKOUT_SESSION } from "../utils/mutations";
import TaskDetails from "../components/TaskDetails";
import { AppContext } from "../contexts/app_context";
import CustomChart from "../components/charts/CustomChart";
import { GET_CSV_FOR_TASK } from "../utils/mutations";
import ImportSectionModal from "../components/ImportSection/ImportSectionModal";
import { THEME_DARK_GREEN, THEME_YELLOW } from "../utils/colors";
import SubmissionImages from "../components/SubmissionImages";

function SubmissionsPage(props) {
  const { id } = useParams();
  const history = useHistory();
  const appState = useContext(AppContext);

  const [showImport, setShowImport] = useState(false);

  const [state, setState] = useState({
    downloading: false,
    downloadErr: false,
  });

  const taskGroupQuery = useQuery(GET_TASK_GROUP, {
    variables: { taskGroupId: id },
    fetchPolicy: "no-cache",
  });

  appState.shouldShowSectionActions =
    taskGroupQuery.data &&
    taskGroupQuery.data.taskGroup.state === "Payment Due";

  const submissionQuerry = useQuery(GET_SUBMISSIONS_LIST, {
    variables: { taskGroup: id },
    fetchPolicy: "no-cache",
  });

  const responseSummary = useQuery(RESPONSE_SUMMARY, {
    variables: { taskGroup: id },
    skip: appState.shouldShowSectionActions,
  });
  const budgetSummary = useQuery(BUDGET_SUMMARY, {
    variables: { taskGroup: id },
    skip: appState.shouldShowSectionActions,
  });

  const [getCSV] = useMutation(GET_CSV_FOR_TASK);

  const [getCheckoutSession] = useMutation(CHECKOUT_SESSION);
  const [checkoutState, setCheckoutState] = useState({
    loading: false,
    error: false,
  });

  const handleCheckout = async () => {
    if (appState.user.stripeId) {
      history.push("/tasks/" + id + "/payment/success");
    } else {
      setCheckoutState({
        error: false,
        loading: true,
      });
      getCheckoutSession({ variables: { taskGroup: id } })
        .then(async (res) => {
          const sessionId = res.data.checkoutSession.sessionId;
          const stripe = await loadStripe(appState.keys.stripe);
          await stripe
            .redirectToCheckout({
              sessionId,
            })
            .catch((error) => {
              console.log("ON REDIRECT ERROR" + error);
              setCheckoutState((prevState) => ({
                ...prevState,
                error: true,
              }));
            });

          setCheckoutState((prevState) => ({
            ...prevState,
            loading: false,
          }));
        })
        .catch((err) => {
          console.log("ON Error: " + err);
          setCheckoutState({
            error: true,
            loading: false,
          });
        });
    }
  };

  const downloadCSV = (e) => {
    e.preventDefault();
    setState(() => ({
      downloading: true,
      downloadErr: false,
    }));
    getCSV({
      variables: { id: taskGroupQuery.data.taskGroup.id },
    })
      .then((result) => {
        const fileUri = result.data.generateCsv.taskGroup.responseCsv;
        if (fileUri) {
          window.location.href = fileUri;
          setState((prevState) => ({
            ...prevState,
            downloading: false,
          }));
        } else {
          setState(() => ({
            downloading: false,
            downloadErr: true,
          }));
        }
      })
      .catch((error) => {
        console.log("COULDN'T GENERATE THE CSV FILE" + error);
        setState(() => ({
          downloading: false,
          downloadErr: true,
        }));
      });
  };

  const getCheckoutButton = () => {
    if (
      taskGroupQuery.data &&
      taskGroupQuery.data.taskGroup.state === "Payment Due" &&
      submissionQuerry.data &&
      submissionQuerry.data.corporateSubmissionList.length &&
      appState.keys.stripe
    ) {
      return (
        <Box my={1}>
          {checkoutState.loading ? (
            <Button variant="contained" color="primary">
              <CircularProgress />
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              role="link"
              onClick={handleCheckout}
            >
              Continue
            </Button>
          )}
          {checkoutState.error && (
            <Typography variant="subtitle1" color="error">
              Couldn't continue at the moment.
            </Typography>
          )}
        </Box>
      );
    } else {
      return null;
    }
  };

  if (!taskGroupQuery.loading && !taskGroupQuery.data.taskGroup) {
    return <Redirect to={{ pathname: "/page-not-found" }} />;
  }
  return (
    <AppDrawer pageName={SUBMISSIONS_PAGE}>
      <Box my={1}>
        <Box my={2} className="page-bar">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="h5" color="textSecondary">
              Task Details
            </Typography>
            {appState.shouldShowSectionActions && (
              <Button
                variant="contained"
                color="secondary"
                component={Link}
                to={{
                  pathname: `/tasks/${id}`,
                  state: {
                    task: taskGroupQuery.data
                      ? taskGroupQuery.data.taskGroup
                      : null,
                  },
                }}
              >
                Edit
              </Button>
            )}
          </Box>
        </Box>
        <Box>
          <TaskDetails taskQuery={taskGroupQuery} />
        </Box>
        <Box m={2}>
          <Typography variant="h5" color="textPrimary">
            <Box fontWeight="fontWeightBold">Sections</Box>
          </Typography>
          {appState.shouldShowSectionActions && (
            <Box className="section-buttons">
              <Button
                variant="contained"
                color="primary"
                component={Link}
                to={props.location.pathname + "/form"}
                startIcon={<AddIcon style={{ fontSize: "x-large" }} />}
              >
                Create section
              </Button>
              <Button
                variant="contained"
                color="primary"
                startIcon={<SaveAltIcon style={{ fontSize: "x-large" }} />}
                onClick={() => {
                  setShowImport(true);
                }}
              >
                Import section
              </Button>
              <ImportSectionModal
                showModal={showImport}
                onClose={() => {
                  setShowImport(false);
                }}
              />
            </Box>
          )}
          <SubmissionsList />
          {getCheckoutButton()}
        </Box>

        {taskGroupQuery.data &&
          (taskGroupQuery.data.taskGroup.state === "Closed" ||
            taskGroupQuery.data.taskGroup.state === "Approved") && (
            <Box m={2}>
              <Typography variant="h5" color="textPrimary">
                <Box fontWeight="fontWeightBold">Responses</Box>
              </Typography>
              <Box className="graph-container">
                {responseSummary.data && (
                  <CustomChart
                    title="Responses"
                    values={[
                      [
                        "Expected",
                        responseSummary.data.responseDataSummary
                          .expectedSubmissions,
                      ],
                      [
                        "Received",
                        responseSummary.data.responseDataSummary
                          .approvedSubmissions +
                          responseSummary.data.responseDataSummary
                            .completedSubmissions,
                      ],
                    ]}
                    colors={{
                      Expected: THEME_DARK_GREEN,
                      Received: THEME_YELLOW,
                    }}
                  />
                )}
                {budgetSummary.data && (
                  <CustomChart
                    title="Budget"
                    values={[
                      [
                        "Total",
                        budgetSummary.data.budgetAllocationSummary.totalBudget,
                      ],
                      [
                        "Spent",
                        budgetSummary.data.budgetAllocationSummary.spentBudget,
                      ],
                    ]}
                    colors={{ Total: THEME_DARK_GREEN, Spent: THEME_YELLOW }}
                  />
                )}
              </Box>
              <Box className="response-download">
                <Typography variant="h6" color="textSecondary">
                  Your responses are ready.
                </Typography>
                <Box>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={downloadCSV}
                  >
                    Download CSV
                  </Button>
                  {state.downloading && (
                    <Typography variant="subtitle1" color="textSecondary">
                      downloading...
                    </Typography>
                  )}
                  {state.downloadingErr && (
                    <Typography variant="subtitle1" color="textSecondary">
                      Couldn't download the response file, please try again
                      later.
                    </Typography>
                  )}
                </Box>
              </Box>
              <Box>
                <SubmissionImages taskDetails={taskGroupQuery.data.taskGroup} />
              </Box>
            </Box>
          )}
      </Box>
    </AppDrawer>
  );
}

export default SubmissionsPage;
