import gql from "graphql-tag";

export const GET_CURRENT_USER = gql`
  query {
    me {
      id
      firstName
      stripeId
    }
  }
`;

export const GET_TASK_TAGS = gql`
  query {
    allTags {
      id
      name
    }
  }
`;

export const GET_TASK_GROUPS = gql`
  query {
    allTaskGroups {
      id
      title
      effortRequired
      completionWindow
      amount
      allocationStartDate
      listingAllocationEndDate
      state
      tag {
        id
        name
      }
    }
  }
`;

export const GET_SUBMISSIONS_LIST = gql`
  query CorporateSubmissionList($taskGroup: Int!) {
    corporateSubmissionList(taskGroup: $taskGroup) {
      id
      title
      description
      checkin
      template
      submissionTemplate
      state
    }
  }
`;

export const GET_TASK_GROUP = gql`
  query TaskGroup($taskGroupId: Int!) {
    taskGroup(id: $taskGroupId) {
      id
      title
      description
      effortRequired
      completionWindow
      amount
      studentsRequired
      allocationStartDate
      listingAllocationEndDate
      state
      tag {
        id
        name
      }
      tasks {
        properties {
          postcode
        }
      }
    }
  }
`;

export const GET_SUBMISSION_IMAGES = gql`
  query TaskGroupAttachments($taskGroupId: Int!, $limit: Int, $offset: Int) {
    taskGroupAttachments(
      taskGroup: $taskGroupId
      limit: $limit
      offset: $offset
    ) {
      id
      attachment
      submission {
        task {
          id
        }
      }
    }
  }
`;

export const GET_CLIENT_KEYS = gql`
  query ClientKeys {
    clientKeys {
      stripe
    }
  }
`;

export const GET_SUBMISSION_FORM_IMAGES = gql`
  query submissionAttachments($submission: Int!) {
    submissionAttachments(submission: $submission) {
      id
      attachment
    }
  }
`;

export const RESPONSE_SUMMARY = gql`
  query ResponseDataSummary($taskGroup: Int!) {
    responseDataSummary(taskGroup: $taskGroup) {
      expectedSubmissions
      approvedSubmissions
      completedSubmissions
    }
  }
`;

export const BUDGET_SUMMARY = gql`
  query BudgetAllocationSummary($taskGroup: Int!) {
    budgetAllocationSummary(taskGroup: $taskGroup) {
      totalBudget
      spentBudget
    }
  }
`;
