import React from "react";
import { Typography, Box } from "@material-ui/core";
import { commonStyles } from "../styles/material-styles";

export default function PrivacyPolicy() {
  const common = commonStyles();
  return (
    <Box m={2}>
      <Typography variant="h2" align="center">
        Privacy Policy
      </Typography>
      <p>
        We know that you care how information about you is used and shared, and
        we appreciate your trust that we will do so carefully and sensibly.
      </p>
      <p>
        This privacy policy (“Privacy Policy” or “Policy”) describes how your
        personal information is collected and processed by
        www.unigigster.com website (“Site” or “Website”) and applications, that
        reference this Privacy Policy and that are accessed through any mobile
        applications, platform or device (collectively “Unigigster Services”)
        and its affiliates, Vida Technologies Limited; a company registered in
        England and Wales with Company Number 10217838 (“Vida Technologies”).
        The Privacy Policy also explains how you can exercise your privacy
        rights.
      </p>
      <p>
        Vida Technologies provide Unigigster services and its features to you.
        We are committed to protecting the privacy of all users of the
        Unigigster platform.
      </p>
      <p>
        This Privacy Policy applies to all Users who access or use Unigigster
        Services. We aim to safeguard the privacy of Users by protecting their
        personal information and using it in accordance with data privacy laws. 
        <b>
          By using Unigigster Services, you are consenting to the practices
          described in this Privacy Policy.
        </b>
      </p>
      <p>
        Capitalised terms which are not defined herein shall have the same
        meaning ascribed to them in the <u>Terms of Service Agreement</u>.
      </p>
      <ol className={common.orderedLists}>
        <li>
          How we collect your information
          <ul>
            <li>
              We collect the information you give us via forms accessed on
              Unigigster Services such as your name, address, email address,
              date of birth and phone number (“Personal Information” or
              “Information”)
            </li>
            <li>
              We collect Information when you create an Account with us or
              purchase membership or subscription services offered by us.
            </li>
            <li>
              We collect Information when you complete a gig on the Unigigster
              platform.
            </li>
            <li>
              We collect Information when you get in contact with us directly
              via email, phone, chat or by completing the online contact form
              accessed through Unigigster Services.
            </li>
          </ul>
          <p>
            We collect your Personal Information when you browse the Site / use
            our mobile application and when you use Unigigster Services. We look
            at how Users use our Site to help us improve Unigigster Services and
            as such may collect technical information such as IP addresses, and
            devices and operating systems used when accessing the Site.
          </p>
        </li>
        <li>
          Why we collect your Information
          <p>
            We only process the Information we have for you if there is a
            legitimate reason for doing so under data protection law. We will
            have a lawful basis for processing your Information if we need to
            process your information to provide you with the Unigigster
            Services, we have your consent or we are under a legal obligation to
            process your data. We collect such Personal Information for one or
            more of the following purposes:
          </p>
          <ul>
            <li>To provide and operate Unigigster Services</li>
            <li>To register your details when you create an account with us</li>
            <li>
              If you have given us permission, we may use your Personal
              Information to send you marketing emails, calls, texts or letters
              with details of Unigigster Services, or that of third-party goods
              and services which may be of interest to you
            </li>
            <li>
              To improve the effectiveness and quality of Unigigster Services we
              provide
            </li>
            <li>To answer questions or provide other types of support</li>
            <li>
              To provide reporting and analytics, testing out features or
              additional services
            </li>
            <li>
              We may use your IP address to recognise when you visit or return
              to the Site / mobile application.
            </li>
            <li>
              To enforce contractual terms with you and for the exercise or
              defence of legal claims to protect the rights of the Companies
            </li>
            <li>
              To monitor communications such as emails and telephone calls for
              quality assurance, training, fraud prevention and compliance
              purposes
            </li>
          </ul>
          <p>
            We process health information about you only where you volunteer and
            consent to this.
          </p>
        </li>
        <li>
          Sharing of Personal Information
          <p>
            We may share your Personal Information with third parties we have
            contracted with to support the Companies’ business operations
            including storage, fulfilment, payment processing, email deployment
            and other data processing.
          </p>
          <p>
            We use Stripe to process payments. Your payment information is
            governed by stringent data protection rules applicable to the
            payment card industry. You can learn more about how Stripe uses your
            data <a href="https://stripe.com/gb/privacy">here</a>.
          </p>
          <p>
            If part or all of the ownership of either of the Companies is sold
            or transferred, your Personal Information will be transferred to the
            new owner.
          </p>
          <p>
            Your Personal Information may be shared where we are required or
            authorised by law.
          </p>
        </li>
        <li>
          Storage and protection of your Personal Information
          <p>
            Your Personal Information is stored by a third party (“Amazon Web
            Services”) via cloud services with whom Vida Technologies have
            contracted with, to support the Companies’ business operations. The
            third party does not use or have access to your Personal Information
            other than for cloud storage and retrieval. We require the third
            party to employ at least the same level of security that we use to
            protect your Personal Information.
          </p>
          <p>
            We maintain safeguards which include technical and organisational
            security measures to protect your data from loss, misuse,
            unauthorised access, alteration and destruction. We require the
            third parties we contract with to employ reasonable security
            measures as well.
          </p>
          <p>
            We also have procedures in place to deal with any suspected data
            security breaches. We will notify you and any applicable regulator
            of a suspected data security breach where we are legally required to
            do so.
          </p>
        </li>
        <li>
          Cookies and local storage in browser
          <p>
            We and/or third parties use cookies, browser’s local storage and
            other tracking technologies on our Site.
          </p>
          <p>
            A cookie is an identifier (a small file of letters and numbers) that
            is sent to your computer. Session cookies are temporary cookies that
            remain in the cookie file of your browser only until your browser is
            closed. They allow websites to link your actions during a browser
            session. Persistent cookies stay in the cookie file of your browser
            for longer (though how long will depend on the lifetime of the
            specific cookie).
          </p>
          <p>
            A local storage is a file created by a website in your device.
            Cookies are just one type of local storage, but there are others. It
            is important that you know them so you can block them or delete the
            data stored by them:
          </p>
          <ul>
            <li>
              Browser local storage. Some websites use the browser’s
              “sessionStorage” and “localStorage” to store data.
              “sessionStorage” is stored temporarily, but the “localStorage” is
              persistent. You may erase them by deleting your browser’s history.
            </li>
          </ul>
          <p>
            Cookies and browser’s local storage are widely used to make websites
            work, or work more efficiently, as well as to provide information to
            the website owner or other interested parties. Our Website’s
            functionality will be limited if you configure your browser to
            reject cookies.
          </p>
          <p>Cookies are used on our Website for the following purposes:</p>
          <p>
            We use user-input cookies for the duration of a session to keep
            track of a user’s input when filling in forms that span several
            pages. We also use functional cookies to remember choices you’ve
            made or information you’ve provided, such as your username,
            language, or the region you are in. This allows us to tailor your
            website experience specifically to your preferences. For example,
            authentication cookies are functional cookies that are used for the
            duration of a session (or persistent, if you agree to the “remember
            me” function) to allow users to authenticate themselves on
            subsequent visits or to gain access to authorized content across
            pages. The functional cookies we use include:
          </p>
          <ul>
            <li>
              User-centric security cookies to detect authentication abuses for
              a limited persistent duration, like repeated failed login
              attempts. These cookies are set for the specific task of
              increasing the security of the service.
            </li>
            <li>
              Multimedia content player session cookies (flash cookies) are used
              for the duration of a session to store technical data needed to
              play back video or audio content (e.g. image quality, network link
              speed, and buffering parameters).
            </li>
            <li>
              User interface customization persistent cookies are used to store
              a user’s preference regarding a service across web pages.
            </li>
          </ul>
          <p>
            Unigigster is dedicated to user experience and we use many tools to
            help us improve our website and our commerce platform. To this end,
            we use reporting and analytics cookies to collect information about
            how you use our website and the mobile application. These cookies
            only gather information for statistical purposes and only use
            pseudonymous cookie identifiers that do not directly identify you.
            The performance cookies we use include:
          </p>
          <ul>
            <li>
              First party analytics cookies - We use these cookies to estimate
              the number of unique visitors, to improve our websites and our
              merchants’ websites, and to detect the most searched for words in
              search engines that lead to a webpage. These cookies are not used
              to target you with online marketing. We use these cookies to learn
              how our websites and our merchants’ websites are performing and
              make relevant improvements to improve your browsing experience.
            </li>
            <li>
              Third party analytics cookies - We also use Google Analytics and
              other third-party analytics providers listed below to help measure
              how users interact with our website content. These cookies
              “remember” what our users have done on previous pages and how
              they’ve interacted with the website. For more information, see
              below.
            </li>
          </ul>
          <p>
            Finally, Social and Content cookies are placed by many social media
            plugins (for example the Facebook ’like’ button), and other tools
            meant to provide or improve the content on a website (for example
            services that allow the playing of video files, or that create
            comments sections). We integrate these modules into our platform to
            improve the experience of browsing and interacting with our
            websites. Please note that some of these third-party services place
            cookies that are also used for things like behavioural advertising,
            analytics, and/or market research.
          </p>
          <p>
            We use pixels and tags from the following third parties, which may
            in turn place cookies:
          </p>
          <p className={common.boldFont}>Google Analytics</p>
          <p>
            We use Google Analytics to help measure how Users interact with our
            websites. We use Google Ads to deliver targeted advertisements to
            individuals who visit our websites. You can learn more about how
            Google Analytics uses your data 
            <a href="https://policies.google.com/privacy">here</a>.
          </p>
          <p className={common.boldFont}>Facebook Custom Audiences</p>
          <p>
            We use Facebook Custom Audiences to deliver targeted advertisements
            to individuals who visit our websites. We use Facebook Connect to
            allow visitors to our website to interact with and share content via
            Facebook’s social media platform. You can learn more about how
            Facebook uses your data{" "}
            <a href="https://www.facebook.com/policy.php">here</a>.
          </p>
          <p className={common.boldFont}>Twitter</p>
          <p>
            We use Twitter to allow visitors to our website to interact with and
            share content via Twitter’s social media platform. You can learn
            more about how Twitter uses your data{" "}
            <a href="https://twitter.com/en/privacy">here</a>.
          </p>
          <p className={common.boldFont}>LinkedIn Analytics</p>
          <p>
            We use Twitter to allow visitors to our website to interact with and
            share content via Twitter’s social media platform. You can learn
            more about how Twitter uses your data{" "}
            <a href="https://www.linkedin.com/legal/privacy-policy">here</a>.
          </p>
          <p className={common.boldFont}>YouTube</p>
          <p>
            We use YouTube to display video content. You can learn more about
            how YouTube uses your data{" "}
            <a href="https://policies.google.com/privacy?hl=en-US">here</a>.
          </p>
          <p>
            For more information on how to modify your browser settings or how
            to block, manage or filter cookies can be found in your browser’s
            help file or{" "}
            <a href="https://policies.google.com/privacy?hl=en-US">here</a>.
          </p>
        </li>
        <li>
          Your Rights
          <p>
            Under the General Data Protection Regulation, you have a number of
            important rights. In summary, those rights include the following:
          </p>
          <ul>
            <li>
              the right to access - you can ask for copies of your Personal
              Information;
            </li>
            <li>
              the right to rectification - you can ask us to rectify inaccurate
              Personal Information and to complete incomplete Personal
              Information;
            </li>
            <li>
              the right to erasure - you can ask us to erase your Personal
              Information;
            </li>
            <li>
              the right to restrict processing - you can ask use to restrict the
              processing of your Personal Information;
            </li>
            <li>
              the right to object to processing - you can object to the
              processing of your Personal Information;
            </li>
            <li>
              the right to data portability - you can ask that we transfer your
              Personal Information to another organisation or to you;
            </li>
            <li>
              the right to complain to a supervisory authority - you can
              complain about our processing of your Personal Information; and
            </li>
            <li>
              the right to withdraw consent - to the extent that the legal basis
              of our processing of your Personal Information is consent, you can
              withdraw that consent.
            </li>
          </ul>
          <p>
            These rights are subject to certain limitations and exceptions. You
            can learn more about these rights 
            <a href="https://ico.org.uk/for-organisations/guide-to-data-protection/guide-to-the-general-data-protection-regulation-gdpr/individual-rights/">
              here
            </a>
            .
          </p>
          <p>
            You may exercise any of your rights in relation to your Personal
            Information by written notice to us using the contact details set
            out below.
          </p>
        </li>
        <li>
          Amendments
          <p>
            We may update this Policy from time to time, without notice, by
            publishing a new version on our Website. You should check this page
            occasionally to ensure you are happy with any changes to this
            Policy. By continuing to use Unigigster Services after a revised
            version of this policy has been posted, you agree to comply with the
            latest version of this Policy.
          </p>
        </li>
        <li>
          Contact Details
          <p>
            Any queries you have about this Privacy Policy or any general
            queries about how we handle your data can be directed to us at the
            following address: 
            <a href="mailto: contact@unigigster.com">contact@unigigster.com</a>
          </p>
        </li>
      </ol>
    </Box>
  );
}
