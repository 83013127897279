import { TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";

export default function CharCountTextField({
  label,
  onError = false,
  onComplete,
  charLimit,
  shouldFocus = false,
  defaultValue,
}) {
  const [state, setState] = useState({
    value: defaultValue ? defaultValue : "",
    showError: onError,
  });

  useEffect(() => {
    setState((ps) => ({
      ...ps,
      showError: onError,
    }));
  }, [onError]);

  return (
    <TextField
      placeholder={label}
      variant="filled"
      fullWidth
      multiline
      margin="normal"
      error={state.showError}
      inputProps={{
        maxLength: charLimit,
      }}
      value={state.value}
      onChange={(e) => {
        setState({
          value: e.target.value,
          showError: false,
        });
      }}
      FormHelperTextProps={
        state.showError
          ? {}
          : {
              className: "char-count",
            }
      }
      helperText={
        state.showError ? label : `${state.value.length}/${charLimit}`
      }
      autoFocus={shouldFocus}
      onBlur={(e) => onComplete(state.value)}
    />
  );
}
