import React, { useContext, useEffect, useState } from "react";
import {
  Card,
  CardContent,
  List,
  Checkbox,
  Divider,
  Box,
  CircularProgress,
  Grid,
  Typography,
  Button,
} from "@material-ui/core";
import SubmissionItem from "./SubmissionItem";
import { useQuery } from "@apollo/react-hooks";
import { GET_SUBMISSIONS_LIST } from "../../utils/queries";
import { useHistory, useParams } from "react-router-dom";
import { AppContext } from "../../contexts/app_context";

function SubmissionsList() {
  const [checkAll, setCheckAll] = useState(false);
  const { id } = useParams();
  const submissionQuerry = useQuery(GET_SUBMISSIONS_LIST, {
    variables: { taskGroup: id },
    fetchPolicy: "no-cache",
  });
  const history = useHistory();
  const appState = useContext(AppContext);
  useEffect(() => {
    if (appState.refetchSection) {
      onRefetch();
    }
  });

  let content;
  const onCheckAll = () => {
    setCheckAll(!checkAll);
  };
  const onRefetch = () => {
    submissionQuerry.refetch();
    appState.refetchSection = false;
  };

  if (submissionQuerry.loading) {
    content = (
      <CardContent>
        <Grid container justify="center">
          <CircularProgress />
        </Grid>
      </CardContent>
    );
  } else if (
    submissionQuerry.data &&
    submissionQuerry.data.corporateSubmissionList.length
  ) {
    content = (
      <CardContent className="section-card-content">
        <Box my={1} onClick={onCheckAll} className="select-all-box">
          <Checkbox onClick={onCheckAll} checked={checkAll} color="primary" />
          <Typography variant="subtitle1">Select all</Typography>
        </Box>
        <Divider />
        <List className="section-lists">
          {submissionQuerry.data.corporateSubmissionList
            .slice(0, 5)
            .map((submission, index) => {
              return (
                <SubmissionItem
                  key={submission.id}
                  index={index}
                  submissionData={submission}
                  onCheckAll={checkAll}
                  onRefetch={() => {
                    submissionQuerry.refetch();
                  }}
                />
              );
            })}
        </List>
        {submissionQuerry.data.corporateSubmissionList.length > 5 && (
          <Box className="view-all-btn">
            <Button
              onClick={() => {
                history.push({
                  pathname: history.location.pathname + "/all",
                  state: {
                    sections: submissionQuerry.data.corporateSubmissionList,
                  },
                });
              }}
            >
              View all
            </Button>
          </Box>
        )}
      </CardContent>
    );
  } else {
    content = (
      <CardContent>
        <Typography align="center">
          Once you create a section, it would appear here.
        </Typography>
      </CardContent>
    );
  }

  return (
    <Box my={1}>
      <Card>{content}</Card>
    </Box>
  );
}

export default SubmissionsList;
