import React, { useState } from "react";
import { Box, Typography, Button, makeStyles } from "@material-ui/core";
import { commonStyles } from "../styles/material-styles";
import { ACCEPT_COOKIES_BG } from "../utils/colors";
import { COOKIE_STATUS } from "../utils/constants";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    position: "fixed",
    bottom: 0,
    backgroundColor: ACCEPT_COOKIES_BG,
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "1rem",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
    },
    zIndex: 1,
  },
  textStyle: {
    color: "white",
    "& a": {
      color: theme.palette.primary.main,
      textDecorationColor: "white",
    },
  },
}));

export default function AcceptCookie() {
  const common = commonStyles();
  const custom = useStyles();

  const status = localStorage.getItem(COOKIE_STATUS);
  const [cookie, setCookie] = useState(status);

  const onAccepted = () => {
    localStorage.setItem(COOKIE_STATUS, "accepted");
    setCookie(localStorage.getItem(COOKIE_STATUS));
  };

  if (cookie) {
    return null;
  }
  return (
    <Box className={custom.container}>
      <Typography className={custom.textStyle}>
        By using our site, you acknowledge that you have read and understand our{" "}
        <a href="/privacy-policy">Privacy Policy</a> and our{" "}
        <a href="/terms-and-conditions">Terms of Service</a>. We use cookies to
        improve your experience on our site.
      </Typography>
      <Box mt={1} mx={2} px={2}>
        <Button
          variant="contained"
          color="primary"
          className={common.themeButton}
          onClick={onAccepted}
        >
          I Understand
        </Button>
      </Box>
    </Box>
  );
}
