import React, { useContext, useEffect, useState } from "react";
import { Box, Typography, Switch } from "@material-ui/core";
import { FormBuilderContext } from "../../../contexts/app_context";
import {
  getQuestionImageSection,
  getQuestionTextfield,
  getRequiredText,
} from "../../../utils/utilities";
import CustomTooltip from "../../CustomToolTip";
import getFormbuilderIcon from "../../../utils/custom-icons";
import {
  DELETE_SECTION_IMAGE,
  UPLOAD_SECTION_IMAGE,
} from "../../../utils/mutations";
import { useMutation } from "@apollo/react-hooks";

export default function TextTypeField({ element }) {
  const itemContext = useContext(FormBuilderContext);

  const [fields, setFields] = useState({
    label: element.onResult ? element.onResult.label : "",
    labelError: element.onError,
    required: element.onResult ? element.onResult.required : false,
    image: null,
    imageId: element.onResult ? element.onResult.image : null,
  });

  const [uploadImage] = useMutation(UPLOAD_SECTION_IMAGE);
  const [deleteImage] = useMutation(DELETE_SECTION_IMAGE);

  const setLabel = (event) => {
    const value = event.target.value.trim();

    setFields((prevState) => ({
      ...prevState,
      label: value,
      labelError: value ? false : true,
    }));
  };

  const onImagePicked = (e) => {
    const files = e.target.files;
    const [src] = files;
    setFields((prevState) => ({
      ...prevState,
      image: src,
    }));
    if (itemContext.sectionId) {
      uploadImage({
        variables: {
          image1: src,
          responseTemplate: false,
          submission: itemContext.sectionId,
        },
      }).then((result) => {
        if (result.data.uploadSubmissionImages.imageIds.length) {
          const id = result.data.uploadSubmissionImages.imageIds[0];
          setFields((ps) => ({ ...ps, imageId: id }));
          itemContext.refetchAttachments();
        }
      });
    }
  };

  const onImageDelete = () => {
    if (fields.imageId) {
      deleteImage({ variables: { imageId: fields.imageId } });
    }
    setFields((prevState) => ({
      ...prevState,
      image: null,
      imageId: null,
    }));
  };

  useEffect(() => {
    if (fields.label) {
      itemContext.updateItem({
        item: element,
        data: {
          type: element.type,
          label: fields.label,
          max_lines: 1,
          response: null,
          required: fields.required,
          image: fields.imageId,
        },
      });
    } else {
      itemContext.updateItem({ item: element, data: null });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields]);

  return (
    <Box className="question-container">
      <Box className="item-icon-style">{getFormbuilderIcon(element.type)}</Box>
      <Box className="question-fields">
        <Box>
          {getRequiredText("Enter your question", "h6")}
          {getQuestionTextfield(
            fields.label,
            fields.labelError,
            setLabel,
            "Please describe the question for the text field here..."
          )}
        </Box>

        <Box className="add-image">
          {getQuestionImageSection(
            fields.image,
            fields.imageId,
            itemContext.sectionAttachments,
            onImagePicked,
            onImageDelete,
            element.id
          )}
        </Box>
        <Box className="required-switch">
          <Box>
            <Typography color="textPrimary" variant="h6">
              Required
            </Typography>
            <CustomTooltip content="Don't allow to submit the form, if this question is not answered." />
          </Box>
          <Switch
            checked={fields.required}
            onChange={(e) => {
              setFields((prevState) => ({
                ...prevState,
                required: e.target.checked,
              }));
            }}
            color="primary"
          />
        </Box>
      </Box>
    </Box>
  );
}
