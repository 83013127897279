import React, { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  InputAdornment,
  Button,
} from "@material-ui/core";
import MailIcon from "@material-ui/icons/Mail";
import { Loader, validateEmail } from "../../utils/utilities";
import { Link } from "react-router-dom";
import { commonStyles } from "../../styles/material-styles";
import { useMutation } from "@apollo/react-hooks";
import { RESET_PASSWORD_TOKEN } from "../../utils/mutations";

export default function ForgotPassword(props) {
  const common = commonStyles();
  const [state, setState] = useState({
    email: "",
    emailError: false,
    loading: false,
    otherError: "",
    onComplete: false,
  });

  const [resetPasswordToken] = useMutation(RESET_PASSWORD_TOKEN);

  const updateState = (err, exErr = "", load = false) => {
    setState((prevState) => ({
      ...prevState,
      emailError: err,
      otherError: err ? "Please enter a valid email address" : exErr,
      loading: load,
    }));
  };

  const onSubmit = (event) => {
    event.preventDefault();

    if (validateEmail(state.email)) {
      updateState(false, "", true);
      resetPasswordToken({
        variables: {
          email: state.email,
        },
      })
        .then((result) => {
          if (result.data.resetPasswordToken.success) {
            setState((prevState) => ({
              ...prevState,
              loading: false,
              onComplete: true,
            }));
          } else {
            updateState(
              false,
              "Couldn't send the password reset link, please try again later."
            );
          }
        })
        .catch((error) => {
          const { message } = error;
          updateState(false, message);
        });
    } else {
      updateState(true);
    }
  };

  if (state.onComplete) {
    return (
      <Box display="flex" flexDirection="column" alignItems="center">
        <Box my={2}>
          <Typography
            variant="h5"
            align="center"
            className={common.whiteBoldFont}
          >
            Password reset link has been sent to your email.
          </Typography>
        </Box>
        <Button
          variant="contained"
          className={common.loginBtnStyle}
          onClick={props.onToggle}
        >
          Go back
        </Button>
      </Box>
    );
  }
  return (
    <Box m={1}>
      <Typography variant="h5" className={common.whiteBoldFont}>
        Reset Password
      </Typography>
      <Typography variant="subtitle1" style={{ color: "white" }}>
        We'll send you an email to reset your password
      </Typography>
      <form noValidate>
        <TextField
          variant="filled"
          margin="normal"
          required
          fullWidth
          label="Email"
          autoComplete="email"
          className={common.textfieldStyle}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <MailIcon classes={{ root: common.themeIconStye }} />
              </InputAdornment>
            ),
          }}
          onChange={(e) => {
            const { value } = e.target;
            setState((prevState) => ({
              ...prevState,
              email: value,
            }));
          }}
        />
        {state.otherError && (
          <div>
            <p style={{ color: "red" }}>{state.otherError}</p>
          </div>
        )}
        {state.loading && <Loader />}
        <Box
          my={1}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Link
            className={common.loginLinkStyle}
            to="#"
            href="#"
            onClick={props.onToggle}
          >
            Log In
          </Link>
          <Button
            type="submit"
            variant="contained"
            className={common.loginBtnStyle}
            onClick={onSubmit}
          >
            Send Email
          </Button>
        </Box>
      </form>
    </Box>
  );
}
