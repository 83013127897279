import React, { Fragment } from "react";
import { Draggable } from "react-beautiful-dnd";
import "../../styles/global.scss";
import { Box, Typography } from "@material-ui/core";
import getFormbuilderIcon from "../../utils/custom-icons";
import formBuilderLabel from "../../utils/constants";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";

export default function ElementCard(props) {
  const getDpItem = (leadingIcon, title) => {
    return (
      <>
        <Box>
          {leadingIcon}
          <Typography variant="h4" color="textPrimary">
            {title}
          </Typography>
        </Box>
        <Box>
          <DragIndicatorIcon />
        </Box>
      </>
    );
  };
  const icon = getFormbuilderIcon(props.item.type);
  const label = formBuilderLabel(props.item.type);
  const content = getDpItem(icon, label);

  return (
    <Draggable draggableId={props.item.id} index={props.index}>
      {(provided, dragState) => (
        <Fragment>
          <Box
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            className="dg-item"
          >
            {content}
          </Box>
          {dragState.isDragging && (
            <Box className="dnd-item dg-item">{content}</Box>
          )}
        </Fragment>
      )}
    </Draggable>
  );
}
