import React from "react";
import {
  Box,
  Button,
  IconButton,
  LinearProgress,
  TextField,
  Typography,
} from "@material-ui/core";
import ValuesTextField from "../components/form-builder/envelop-fields/ValuesTextField";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import CustomTooltip from "../components/CustomToolTip";

const shortid = require("shortid");

export const validateEmail = (mail) => {
  if (/^[^@]+@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
    return true;
  }
  return false;
};

export const validatePassword = (password) => {
  if (/(?=.*\d)(?=.*[a-z]).{6,}/.test(password)) {
    return true;
  }
  return false;
};

export const Loader = () => {
  return (
    <Box m={1}>
      <LinearProgress />
    </Box>
  );
};

export const getRequiredText = (label, type) => {
  return (
    <Typography color="textPrimary" variant={type}>
      {label}
      <abbr className="required">*</abbr>
    </Typography>
  );
};

export const getQuestionTextfield = (
  label,
  labelError,
  onBlurred,
  placeholder
) => {
  if (label) {
    return (
      <TextField
        variant="filled"
        name="label"
        fullWidth
        placeholder={placeholder}
        autoFocus
        onKeyDown={(e) => {
          if (e.key === "Enter") document.activeElement.blur();
        }}
        defaultValue={label}
        error={labelError}
        helperText={labelError && "Please enter a value for the question."}
        onBlur={onBlurred}
        multiline
      />
    );
  } else {
    return (
      <TextField
        variant="filled"
        name="label"
        fullWidth
        placeholder={placeholder}
        autoFocus
        onKeyDown={(e) => {
          if (e.key === "Enter") document.activeElement.blur();
        }}
        error={labelError}
        helperText={labelError && "Please enter a value for the question."}
        onBlur={onBlurred}
        multiline
      />
    );
  }
};

export const getQuestionImageBtn = (onImageSelected, selected, id) => {
  const inputBtn = (
    <Box>
      <input accept="image/*" id={id} type="file" onChange={onImageSelected} />
      <label htmlFor={id}>
        <Button color="primary" component="span">
          Add image
        </Button>
      </label>
    </Box>
  );
  return selected ? (
    <Box>
      <img
        src={URL.createObjectURL(selected)}
        width={200}
        height={150}
        alt={selected.name}
      />
    </Box>
  ) : (
    inputBtn
  );
};

export const getQuestionImageSection = (
  image,
  imageId,
  attachments,
  onPicked,
  onRemove,
  elementId
) => {
  if (imageId && attachments.length) {
    const url = attachments.find((item) => item.id === imageId);

    return (
      <Box display="flex" alignItems="center" my={1}>
        {url && <img src={url.attachment} alt="" width={200} height={150} />}
        <Box>
          <IconButton onClick={onRemove}>
            <HighlightOffIcon color="error" />
          </IconButton>
        </Box>
      </Box>
    );
  } else {
    return (
      <>
        {getQuestionImageBtn(onPicked, image, elementId)}
        {image ? (
          <IconButton onClick={onRemove}>
            <HighlightOffIcon color="error" />
          </IconButton>
        ) : (
          <Box className="tool-tip">
            <CustomTooltip content="Add an image to this question." />
          </Box>
        )}
      </>
    );
  }
};

export const getValuesField = (
  values,
  onValueSetClb,
  onValueRemoveClb,
  element,
  shouldAdd
) => {
  if (values.length) {
    let elements = values.map((item, index) => (
      <ValuesTextField
        key={item}
        onValue={onValueSetClb}
        onRemove={onValueRemoveClb}
        index={index}
        item={item}
        showDelete={true}
        type={element.type}
      />
    ));
    if (values.length < 2) {
      elements.push(
        <ValuesTextField
          key={shortid.generate()}
          onValue={onValueSetClb}
          index={values.length}
          showDelete={false}
          type={element.type}
          shouldFocus={true}
        />
      );
    }
    if (shouldAdd) {
      elements.push(
        <ValuesTextField
          key={shortid.generate()}
          onValue={onValueSetClb}
          index={values.length + 1}
          type={element.type}
          shouldFocus={true}
        />
      );
    }
    return <>{elements}</>;
  } else {
    return (
      <>
        <ValuesTextField
          onValue={onValueSetClb}
          index={values.length}
          type={element.type}
        />
        <ValuesTextField
          onValue={onValueSetClb}
          index={values.length + 1}
          type={element.type}
        />
      </>
    );
  }
};
