import { useQuery } from "@apollo/react-hooks";
import {
  Typography,
  Box,
  Card,
  CardContent,
  List,
  Checkbox,
  Divider,
  Grid,
  CircularProgress,
} from "@material-ui/core";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import AppDrawer from "../components/AppDrawer";
import SubmissionItem from "../components/submissions-list/SubmissionItem";
import { VIEW_ALL_SECTIONS_PAGE } from "../utils/constants";
import { GET_SUBMISSIONS_LIST } from "../utils/queries";

export default function ViewAllSections() {
  const { id } = useParams();
  const [checkAll, setCheckAll] = useState(false);

  const submissionQuerry = useQuery(GET_SUBMISSIONS_LIST, {
    variables: { taskGroup: id },
    fetchPolicy: "no-cache",
  });

  const onCheckAll = () => {
    setCheckAll(!checkAll);
  };
  let content;
  if (submissionQuerry.loading) {
    content = (
      <CardContent>
        <Grid container justify="center">
          <CircularProgress />
        </Grid>
      </CardContent>
    );
  } else if (
    submissionQuerry.data &&
    submissionQuerry.data.corporateSubmissionList.length
  ) {
    content = (
      <CardContent className="section-card-content">
        <Box my={1} onClick={onCheckAll} className="select-all-box">
          <Checkbox onClick={onCheckAll} checked={checkAll} color="primary" />
          <Typography variant="subtitle1">Select all</Typography>
        </Box>
        <Divider />
        <List className="section-lists">
          {submissionQuerry.data.corporateSubmissionList.map(
            (submission, index) => {
              return (
                <SubmissionItem
                  key={submission.id}
                  index={index}
                  submissionData={submission}
                  onCheckAll={checkAll}
                  onRefetch={() => {
                    submissionQuerry.refetch();
                  }}
                />
              );
            }
          )}
        </List>
      </CardContent>
    );
  } else {
    content = (
      <CardContent>
        <Typography align="center">
          Once you create a section, it would appear here.
        </Typography>
      </CardContent>
    );
  }

  return (
    <AppDrawer pageName={VIEW_ALL_SECTIONS_PAGE}>
      <Box my={1}>
        <Box my={2} className="page-bar">
          <Box>
            <Typography variant="h5" color="textSecondary">
              Sections
            </Typography>
          </Box>
        </Box>
        <Box mx={2}>
          <Card>{content}</Card>
        </Box>
      </Box>
    </AppDrawer>
  );
}
