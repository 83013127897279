import React, { useState } from "react";

import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import {
  Typography,
  Checkbox,
  InputAdornment,
  IconButton,
  Box,
} from "@material-ui/core";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import CustomTooltip from "../CustomToolTip";

function EndDatePicker({ defaultValue, onChange, showError }) {
  var minimumDate = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    new Date().getDate() + 1
  );
  const [endDate, setEndDate] = useState({
    date: defaultValue ? new Date(defaultValue) : minimumDate,
    indefinite: false,
  });

  const onEndDateSelected = (selectedDate) => {
    if (selectedDate) {
      setEndDate((prevState) => ({
        ...prevState,
        date: selectedDate,
      }));
      onChange(selectedDate);
    }
  };

  const onChecked = (e) => {
    if (e.target.checked) {
      const maxDate = new Date(2100, 0, 1);
      setEndDate((prevState) => ({
        ...prevState,
        date: maxDate,
        indefinite: true,
      }));
      onChange(maxDate);
    } else {
      setEndDate((prevState) => ({
        ...prevState,
        date: minimumDate,
        indefinite: false,
      }));
      onChange(minimumDate);
    }
  };

  return (
    <Box maxWidth="220px">
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          style={{ width: "220px" }}
          inputVariant="filled"
          variant="filled"
          autoOk
          format="dd/MM/yyyy"
          margin="normal"
          disablePast
          onChange={onEndDateSelected}
          value={endDate.date}
          disabled={endDate.indefinite}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton>
                  <CalendarTodayIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          minDate={minimumDate}
          error={showError}
        />
      </MuiPickersUtilsProvider>
      <Box className="infinite-deadline">
        <Checkbox color="primary" onChange={onChecked} />
        <Box className="infinite-desc">
          <Typography variant="subtitle2">
            Don't prefer a deadline for this task.
          </Typography>
          <CustomTooltip content="Check this option to run this task indefinitely" />
        </Box>
      </Box>
    </Box>
  );
}

export default EndDatePicker;
