import React from "react";
import { Box, Typography } from "@material-ui/core";
import { commonStyles } from "../styles/material-styles";

export default function TermsConditions() {
  const common = commonStyles();

  return (
    <Box m={2}>
      <Typography variant="h2" align="center">
        Terms and Conditions
      </Typography>
      <Box my={1}>
        <Typography className={common.boldFont} variant="h5">
          General terms and conditions (Gig workers)
        </Typography>
        <p style={{ margin: 0, textAlign: "justify" }}>
          This Terms of Service Agreement (“Agreement” or “Terms of Service” or
          “Terms”) together with the 
          <a href="/privacy-policy">Privacy Policy</a> on
          www.unigigster.com website or the mobile application (Platform) govern
          the use of the Site and applications, that reference this Terms of
          Service and that are accessed through the mobile applications,
          platform or device (collectively “Unigigster platform”).
        </p>
      </Box>
      <ol className={common.orderedLists}>
        <li>
          Scope of Validity of these Terms and Conditions
          <p>
            Unigigster Limited a subsidiary of Vida Technologies Limited
            (hereinafter, “Unigigster”) maintains on its websites and the mobile
            application (hereinafter, “Platform”) in which registered
            participants / students (hereinafter, “Gig worker”) may apply for,
            get accepted and execute advertised tasks.
          </p>
          <ul>
            <li>
              These general terms and conditions (hereinafter and as amended and
              in effect from time to time, “Terms”) apply to and define the
              contractual relationship between Unigigster and the Gig workers.
              Deviations from, or amendments or supplements to, these Terms will
              be valid and effective with the consent of Unigigster. Individual
              or special agreements between Unigigster and specific Gig workers
              will take precedence over these general Terms.
            </li>
            <li>
              Unigigster is entitled to amend, supplement or replace these
              Terms. Unigigster will advise Gig workers of any amendments,
              supplements or replacements of these Terms by e-mail, via the
              mobile application, or in writing. Each Gig worker is hereby
              expressly advised that any such amendment of these Terms will
              become legally binding and incorporated into the contractual
              relationship between each such Unigigster and Gig worker unless
              any such Gig worker objects in writing or by e-mail to any such
              amendment, supplement or replacement within one (1) calendar week
              of notification thereof to Unigigster.
            </li>
          </ul>
        </li>
        <li>
          Establishment of a User Account
          <ul>
            <li>
              In order to use the platform, each prospective Gig worker is
              required to establish a free user account with Unigigster. Any
              prospective Gig worker must be at least 18 years old. Prospective
              Gig worker shall have no legal entitlement to require Unigigster
              to accept their applications to establish a user account and
              Unigigster shall be entitled to accept or reject any such
              application by a prospective Gig worker in its sole and absolute
              discretion.
            </li>
            <li>
              Each prospective Gig worker shall, in applying for a user account,
              be required to provide requested personal data in an orderly,
              accurate and truthful manner. Unigigster reserves the right to
              request and require appropriate documentation or other proof of
              the personal information provided by any prospective Gig worker
              prior to accepting any such application for a user account.
            </li>
            <li>
              Each Gig worker is required to update and maintain the accuracy of
              all data provided and to undertake any necessary changes
              immediately and without delay. Unigigster will provide Gig worker
              with a mechanism in the platform to make such changes (if any)
              themselves. Unigigster will not be responsible for its inability
              to contact any Gig worker who fails to update their personal
              information.
            </li>
            <li>
              Each prospective Gig worker shall be required to choose and
              provide an email ID, Mobile number and password to establish a
              user account. Each Gig worker shall be responsible and required to
              maintain the confidentiality of his/her password.
            </li>
            <li>
              Unigigster does not permit any person to establish a user account
              on behalf of third parties. Gig worker are prohibited from
              establishing or maintaining more than one user account.
            </li>
            <li>
              Unigigster shall be entitled to enter into framework contracts
              with any person or entity (regardless of legal form) with respect
              to the use of the platform. Any such person or entity shall
              thereby be entitled itself or through employees to make offers
              pursuant to Section 3, below. However, any such person or entity
              shall be required to ensure that its individual employees may be
              definitively and discretely identified by Unigigster.
            </li>
            <li>
              Acceptance by Unigigster of an application by a prospective Gig
              worker to establish a user account shall not give rise to an
              additional contractual relationship between any such Gig worker
              and Unigigster.
            </li>
            <li>
              Unigigster reserves the right to expunge and delete the user
              account of any Gig worker and to terminate its contractual
              relationship with any such Gig worker if she/he violates these
              Terms and/or violates any other obligations arising from this
              contractual relationship. In the event thereof, any such Gig
              worker will receive payment of any amounts recorded in his / her
              user account in respect of services rendered.
            </li>
            <li>
              Gig worker shall be entitled to delete and expunge their user
              accounts on the platform at any time on their own initiative.
              Notwithstanding the foregoing, any information or data legally
              required to be maintained (such as, for example, invoices and
              credits) will be expunged and deleted only on expiration of
              applicable legal requirements.
            </li>
          </ul>
        </li>
        <li>
          Gig worker Offers / Use of the platform
          <ul>
            <li>
              Unigigster will present gigs in the platform together with any
              applicable conditions relating thereto (in each case only as an
              “invitation to submit an offer”). On acceptance and establishment
              of their user accounts, Gig workers may review gigs that
              correspond to their interests. Gig workers may submit offers to
              Unigigster to undertake gigs subject to the conditions set forth
              in the applicable gig description. Unigigster is not required to
              accept any such offer(s). Consequently, submission by Unigigster
              of project descriptions in the platform shall not constitute
              binding offers by Unigigster.
            </li>
            <li>
              Should Unigigster accept an offer by a Gig worker, then the data
              necessary for the completion of any such gig shall be made
              available in the platform. Acceptance of the offer(s) of any Gig
              worker(s) by Unigigster shall not give rise to any entitlement or
              expectation by Unigigster that any such Gig workers(s) will in
              fact complete any such gig(s) in accordance with the conditions
              thereof.
            </li>
            <li>
              In return, Unigigster shall not be required to accept any work
              product of any Gig Worker that does not satisfy the conditions
              prescribed therefore in the applicable gig description and which,
              as a consequence, is inadequate and unsatisfactory. Without
              limitation, Unigigster will not accept any work product submitted
              after expiration of the deadline prescribed therefore.
            </li>
            <li>
              Each Gig Worker agrees to keep confidential vis-a-vis any third
              parties any and all data or gig descriptions provided in
              connection with his / her performance of work for any project and
              to use any such data or gig descriptions exclusively for the
              performance of work for Unigigster. Without limitation, each Gig
              Worker must work and produce work product in a manner such that
              third parties have no access to any such data or gig descriptions
              provided by Unigigster. These confidentiality obligations shall
              survive any termination of these Terms or the contractual
              relationship between any Gig Worker and Unigigster. Unigigster
              reserves the right to exercise any and all available legal
              remedies (including without limitation by obtaining injunctive
              relief or pursuing claims for damages) against Gig Worker that
              culpably violate these provisions.
            </li>
            <li>
              Any work product delivered will be proofed and reviewed by
              authorised accepting entity. Should any such review reveal
              deficiencies in the work product, then such Gig Worker will
              receive an option to correct said work product. Should any such
              revisions by a Gig Worker be inadequate or should a Gig Worker
              refuse to revise deficient work product, then Unigigster will be
              deemed to have rejected and terminated the applicable contract
              with such Gig Worker. A Gig Worker will not be owed any
              compensation in any such case. In certain situations, there will
              be no possibility for a Gig Worker to revise or correct deficient
              work product if, for example, gigs must be finally completed by a
              specific deadline. In any such situation such special condition
              will be set forth in the applicable gig description.
            </li>
            <li>
              If Unigigster finally accepts any work product, then such Gig
              Worker will receive a credit to his / her user account of the
              compensation provided therefor in the corresponding gig
              description. Any such compensation will be paid as provided in
              section 4, below.
            </li>
            <li>
              With respect to any gig, Unigigster will exclusively mandate the
              Gig Worker who has submitted an offer to perform services relating
              thereto to complete such gig. Gig Workers are expressly prohibited
              from subcontracting or outsourcing gigs to third parties unless
              this is expressly permitted by the terms of a gig description.
            </li>
            <li>
              Each Gig Worker is responsible for complying with any and all tax
              laws applicable to his or her business relationship with
              Unigigster in the United Kingdom. In particular, each Gig Worker
              shall be responsible for paying all taxes and deductions required
              in connection therewith.
            </li>
          </ul>
        </li>
        <li>
          Management of User Accounts
          <ul>
            <li>
              A Gig worker delivering work product in a timely manner in
              compliance with Section 3, above, that corresponds to the relevant
              gig description shall receive credit into his/her user account of
              the compensation set forth in the corresponding gig description.
              The said credit will be processed upon final acceptance of such
              work product.
            </li>
            <li>
              Once the compensation of the Gig worker has been finally credited
              into his/her user account, the compensation will be paid out in
              accordance with the provisions of the Stripe payment system - via
              money transfer to a bank account named by the Gig worker. The Gig
              worker shall provide Unigigster with the information necessary for
              making such payment. 
            </li>
          </ul>
        </li>
        <li>
          Rights to Project Work Product
          <ul>
            <li>
              Each Gig worker unconditionally assigns to Unigigster the right to
              use the work product and services produced without limitations.
              This assignment of rights pertains to and encompasses without
              limitation all rights of authorship and copyright and trademark
              rights. This assignment of rights applies to all forms of use,
              including without limitation the rights to duplicate, disseminate,
              perform, reproduce in public and publish, work upon and amend or
              alter any applicable (if any) rights of authorship or other
              protective rights. This assignment of rights includes without
              limitation the rights of Unigigster to assign to third parties
              (including without limitation, clients of Unigigster)
              corresponding rights to the same extent so that the services and
              work product of Gig workers shall be freely available to them to
              the same extent as to Unigigster. Each Gig worker hereby renounces
              any right to be publicly named or described as the author of any
              such work product.
            </li>
            <li>
              Gig workers shall have been compensated for the assignment of such
              rights of use with respect to work product or services accepted by
              Unigigster by payment by Unigigster of compensation pursuant to
              Section 3, above.
            </li>
            <li>
              Each Gig worker represents and warrants to Unigigster that all
              services performed for, and work product delivered to, Unigigster
              do not contain any content in violation of applicable law,
              including without limitation such as a violation of the rights of
              authorship or other protective rights of third parties, or as
              might be illegal under any applicable laws. Gig workers are
              required to reimburse and indemnify Unigigster for all costs and
              expenses incurred by Unigigster owing to claims asserted by third
              parties against Unigigster in connection with content or work
              product furnished by such Gig worker.
            </li>
            <li>
              In this context, Unigigster expressly advises Gig workers that its
              quality control review of the work product furnished by Gig
              workers includes an examination for possible violations of
              copyrights.
            </li>
          </ul>
        </li>
        <li>
          Liability of Unigigster, Risks in Providing Services
          <ul>
            <li>
              Except as otherwise provided in these Terms and in the hereinafter
              following provisions, any liability of Unigigster shall be limited
              as provided in applicable law.
            </li>
            <li>
              Unigigster shall be liable only for damages resulting from wilful
              misconduct and gross negligence on its part. On simple negligence,
              Unigigster shall only be liable for damages relating to injury to
              life, bodily harm or health; damages relating to breach of a
              material contractual obligation (an obligation essential to the
              proper completion of the contract and as to which a Gig worker
              may, and reasonably does, rely); in such case, however, liability
              of Unigigster shall be limited to restitution of foreseeable,
              reasonably expected damages.
            </li>
            <li>
              Gig workers represent and warrant to Unigigster that in performing
              services for, and delivering work product to, Unigigster they are
              not undertaking any risks beyond the ordinary risks of life.
              Unigigster will not publish to the Workplace any gig descriptions
              giving rise to any special risks in connection with the
              performance thereof.
            </li>
          </ul>
        </li>
        <li>
          Data Protection
          <ul>
            <li>
              Use and maintenance of personal data relating to Gig workers by
              Unigigster is subject to the <b>Privacy policy</b>.
            </li>
          </ul>
        </li>
        <li>
          Notices, Applicable Law, Venue
          <ul>
            <li>
              Any notices provided by Gig workers to Unigigster in connection
              with the contractual relationship between them are required to be
              affected in writing or via e-mail. Current contact information of
              Unigigster may be found on the company legal information of
              Unigigster Limited.
            </li>
            <li>
              In the event a dispute arises between Gig workers and Unigigster.
              Any dispute arising from or in relation to the subject matter of
              this Agreement shall be subject to the exclusive jurisdiction of
              the courts of London.
            </li>
            <li>
              The invalidity of specific paragraphs of these Terms shall not
              affect the validity of other paragraphs of these Terms.
            </li>
          </ul>
        </li>
      </ol>
    </Box>
  );
}
