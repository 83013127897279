import React, { useState } from "react";
import { GET_TASK_TAGS } from "../../utils/queries";
import {
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
} from "@material-ui/core";
import { useQuery } from "@apollo/react-hooks";

export default function TagsField({ defaultValue, onChange, showError }) {
  const { data, loading } = useQuery(GET_TASK_TAGS);

  const [tags, setTags] = useState();
  const [selectedTag, setSelectedTag] = useState(defaultValue);
  if (!loading) {
    let tagsList = [];
    if (data.allTags != null) {
      data.allTags.forEach((element) => {
        let tag = {
          tag_id: element.id,
          tag_name: element.name,
        };
        tagsList.push(tag);
      });
      if (!tags) {
        setTags((preValue) => ({
          ...preValue,
          tags_list: tagsList,
        }));
      }
    }
  }

  const handleSelectedTag = (e) => {
    const value = e.target.value;
    setSelectedTag(value);
    onChange(value);
  };

  return (
    <FormControl variant="filled" error={showError}>
      <Select
        name="tags"
        value={selectedTag}
        onChange={handleSelectedTag}
        displayEmpty
      >
        {tags &&
          tags.tags_list.map((tag) => (
            <MenuItem key={tag.tag_id} value={tag.tag_id}>
              {tag.tag_name}
            </MenuItem>
          ))}
      </Select>
      {showError && <FormHelperText>Select a category</FormHelperText>}
    </FormControl>
  );
}
