import React, { useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import { GET_SUBMISSION_IMAGES } from "../utils/queries";
import {
  Card,
  CardContent,
  Grid,
  CircularProgress,
  Typography,
  Box,
  makeStyles,
} from "@material-ui/core";
import { SRLWrapper } from "simple-react-lightbox";

const useStyles = makeStyles((theme) => ({
  thumbsContainer: {
    margin: 8,
    display: "flex",
    flexWrap: "wrap",
  },
  thumbStyle: {
    margin: 8,
    width: 200,
    maxHeight: 150,
    overflow: "hidden",
  },
  showAll: {
    height: 150,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    backgroundColor: "rgba(255, 255, 255, 0.5)",
  },
}));

const lightboxOptions = {
  buttons: {
    showDownloadButton: false,
  },
};

export default function SubmissionImages({ taskDetails }) {
  const classes = useStyles();

  const [showAll, setShowAll] = useState(false);
  const THUMB_PREVIEW_LIMIT = 4;
  const { loading, data, fetchMore } = useQuery(GET_SUBMISSION_IMAGES, {
    variables: {
      taskGroupId: taskDetails.id,
      limit: 5,
      offset: 0,
    },
    fetchPolicy: "network-only",
  });

  const imageThumb = (id, url, taskId) => {
    return (
      <>
        <Box key={id} className={classes.thumbStyle}>
          <a href={url} data-attribute="SRL">
            <img src={url} alt="" width="100%" height="80%" />
          </a>
          <p>Task Id: {taskId}</p>
        </Box>
      </>
    );
  };

  let content;
  if (loading) {
    content = (
      <CardContent>
        <Grid container justify="center">
          <CircularProgress />
        </Grid>
      </CardContent>
    );
  } else if (data && data.taskGroupAttachments.length === 0) {
    return null;
  } else if (data) {
    const elements = data.taskGroupAttachments.map((item, idx) => {
      if (idx === THUMB_PREVIEW_LIMIT && !showAll) {
        return (
          <Box
            key={item.id}
            style={{
              backgroundImage: `url(${item.attachment})`,
              margin: 8,
              width: 200,
            }}
            onClick={() => {
              setShowAll(true);
              fetchMore({
                variables: {
                  offset: data.taskGroupAttachments.length,
                },
                updateQuery: (prev, { fetchMoreResult }) => {
                  if (!fetchMoreResult) return prev;
                  return Object.assign({}, prev, {
                    taskGroupAttachments: [
                      ...prev.taskGroupAttachments,
                      ...fetchMoreResult.taskGroupAttachments,
                    ],
                  });
                },
              });
            }}
          >
            <Box className={classes.showAll}>
              <Typography
                variant="subtitle1"
                style={{ fontWeight: "bold", color: "white" }}
              >
                Show All
              </Typography>
            </Box>
          </Box>
        );
      } else {
        return imageThumb(item.id, item.attachment, item.submission.task.id);
      }
    });

    content = (
      <SRLWrapper options={lightboxOptions}>
        <Box className={classes.thumbsContainer}>{elements}</Box>
      </SRLWrapper>
    );
  } else {
    content = (
      <CardContent>
        <Typography align="center">Images are not available.</Typography>
      </CardContent>
    );
  }

  return (
    <Box my={2}>
      <Typography variant="h5" color="primary" style={{ fontWeight: "bold" }}>
        Submission Images
      </Typography>
      <Card>{content}</Card>
    </Box>
  );
}
