import React, { useState } from "react";
import { Box, TextField, IconButton } from "@material-ui/core";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import { CHECK_CARD, RADIO_CARD } from "../../../utils/constants";

export default function ValuesTextField(props) {
  const initialValue = props.item ? props.item : "";
  const [textValue, setTextValue] = useState(initialValue);
  const [error, setError] = useState(false);

  const onChange = (event) => {
    const tempValue = event.target.value.trim();
    if (tempValue) {
      setError(false);
      setTextValue(tempValue);
      props.onValue({ index: props.index, value: tempValue });
    } else {
      onDelete();
    }
  };

  const onDelete = () => {
    if (props.onRemove) {
      props.onRemove(props.index);
    }
  };

  const minusButton = (
    <Box className="value-action">
      <IconButton onClick={onDelete}>
        <RemoveCircleIcon />
      </IconButton>
    </Box>
  );

  const getIcon = () => {
    switch (props.type) {
      case RADIO_CARD:
        return <RadioButtonUncheckedIcon />;
      case CHECK_CARD:
        return <CheckBoxOutlineBlankIcon />;
      default:
        break;
    }
  };
  return (
    <Box className="value-container">
      <Box className="field">
        {getIcon()}
        <TextField
          name="label"
          fullWidth
          placeholder="Type an option"
          size="small"
          defaultValue={textValue}
          onKeyDown={(e) => {
            if (e.key === "Enter") document.activeElement.blur();
          }}
          error={error}
          helperText={error && "Please enter a value."}
          onBlur={onChange}
          autoFocus={props.shouldFocus}
        />
      </Box>
      {props.showDelete && minusButton}
    </Box>
  );
}
