import React, { Fragment, useContext } from "react";
import { Draggable } from "react-beautiful-dnd";
import { IconButton, Box, Typography } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import {
  TEXT_CARD,
  NUMBER_CARD,
  RADIO_CARD,
  CHECK_CARD,
  IMAGE_CARD,
  GRID_CARD,
  SLIDER_CARD,
  DATE_TIME_CARD,
  URL_BUTTON_CARD,
} from "../../utils/constants";
import TextTypeField from "./envelop-fields/TextTypeField";
import NumberTypeField from "./envelop-fields/NumberTypeField";
import RadioTypeField from "./envelop-fields/RadioTypeField";
import CheckTypeField from "./envelop-fields/CheckTypeField";
import ImageTypeField from "./envelop-fields/ImageTypeField";
import { FormBuilderContext } from "../../contexts/app_context";
import GridTypeField from "./envelop-fields/GridTypeField";
import DateTypeField from "./envelop-fields/DateTypeField";
import SliderTypeField from "./envelop-fields/slider/SliderTypeField";
import UrlButtonTypeField from "./envelop-fields/UrlButtonTypeField";

export default function EnvelopCard(props) {
  const itemContext = useContext(FormBuilderContext);

  const onDelete = () => {
    itemContext.removeItem(props.index);
  };

  const envelopItem = () => {
    switch (props.item.type) {
      case TEXT_CARD:
        return <TextTypeField element={props.item} />;
      case NUMBER_CARD:
        return <NumberTypeField element={props.item} />;
      case RADIO_CARD:
        return <RadioTypeField element={props.item} />;
      case CHECK_CARD:
        return <CheckTypeField element={props.item} />;
      case IMAGE_CARD:
        return <ImageTypeField element={props.item} />;
      case GRID_CARD:
        return <GridTypeField element={props.item} />;
      case DATE_TIME_CARD:
        return <DateTypeField element={props.item} />;
      case SLIDER_CARD:
        return <SliderTypeField element={props.item} />;
      case URL_BUTTON_CARD:
        return <UrlButtonTypeField element={props.item} />;
      default:
        break;
    }
  };

  const content = (
    <Box bgcolor="white">
      <Box className="envelop-card">
        {envelopItem()}
        <Box className="envelop-options">
          <IconButton onClick={onDelete}>
            <DeleteIcon color="error" />
          </IconButton>
        </Box>
      </Box>
      {props.item.onError && (
        <Box pl={2} pb={1}>
          <Typography variant="caption" color="error">
            Please provide the item details.
          </Typography>
        </Box>
      )}
    </Box>
  );

  return (
    <Draggable draggableId={props.item.id} index={props.index}>
      {(provided, dragState) => (
        <Fragment>
          <li
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            {content}
          </li>
        </Fragment>
      )}
    </Draggable>
  );
}
