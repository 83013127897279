import React, { useState } from "react";
import { TextField } from "@material-ui/core";

function StudentsCountField({ defaultValue, onCountChange, countError }) {
  const [studentCount, setStudentCount] = useState({
    count: defaultValue ? defaultValue : "",
  });

  const updateStudentsCount = (e) => {
    let value = e.target.value;
    if (/^([1-9][0-9]{0,2}|1000)$/.test(value) || value === "") {
      setStudentCount((prevState) => ({
        ...prevState,
        count: value,
      }));
    } else {
      setStudentCount((prevState) => ({
        ...prevState,
      }));
    }
  };
  return (
    <TextField
      variant="filled"
      type="number"
      fullWidth
      inputProps={{ min: 1, max: 1000 }}
      value={studentCount.count}
      onChange={(e) => updateStudentsCount(e)}
      placeholder="1-1000"
      name="studentsCount"
      error={countError}
      helperText={countError && "Enter a valid number"}
      onBlur={(e) => onCountChange(e)}
    />
  );
}

export default StudentsCountField;
