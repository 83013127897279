import React, { useState } from "react";
import { Box, TextField } from "@material-ui/core";
import moment from "moment";
import momentFormat from "moment-duration-format";
momentFormat(moment);

function DurationPicker({ defaultValue, onComplete, showError }) {
  const [value, setValue] = useState(
    defaultValue
      ? moment.duration(defaultValue, "seconds").format("hh:mm")
      : "00:00"
  );
  const processTime = (event) => {
    const timeValue = event.target.value;
    setValue(timeValue);
    onComplete(moment.duration(timeValue).asSeconds());
  };
  return (
    <Box my={1}>
      <TextField
        type="time"
        defaultValue={value}
        className="duration-picker"
        onChange={processTime}
        variant="filled"
        inputProps={{
          step: 300,
        }}
        error={showError}
        helperText={showError && "Enter a minimum of 15 mins."}
      />
    </Box>
  );
}

export default DurationPicker;
