import React from "react";
import { Box, CircularProgress, Grid, Typography } from "@material-ui/core";
import { useQuery } from "@apollo/react-hooks";
import { GET_SUBMISSIONS_LIST } from "../../utils/queries";
import SectionTile from "./SectionTile";

export default function TaskTile({ taskId }) {
  const { loading, data } = useQuery(GET_SUBMISSIONS_LIST, {
    variables: { taskGroup: taskId },
  });

  let content;
  if (loading) {
    content = (
      <Grid container justify="center">
        <CircularProgress />
      </Grid>
    );
  } else if (data && data.corporateSubmissionList.length) {
    content = (
      <Box className="section-tile-container">
        {data.corporateSubmissionList.map((item) => {
          return <SectionTile sectionData={item} key={item.id} />;
        })}
      </Box>
    );
  } else {
    content = (
      <Typography variant="subtitle1" color="textPrimary">
        No sections available.
      </Typography>
    );
  }

  return content;
}
