import React, { useState, Fragment, useEffect } from "react";
import {
  ListItem,
  Checkbox,
  Box,
  Typography,
  IconButton,
  Divider,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import DateRangeIcon from "@material-ui/icons/DateRange";
import LocalOfferOutlinedIcon from "@material-ui/icons/LocalOfferOutlined";
import moment from "moment";
import { useHistory } from "react-router-dom";

export default function TaskItem({ onCheckAll, taskGroup, index }) {
  const [check, setCheck] = useState(false);

  const history = useHistory();

  useEffect(() => {
    setCheck(onCheckAll);
  }, [onCheckAll]);

  const handleCheck = () => {
    setCheck(!check);
  };

  const onDelete = () => {
    console.log("ON DELETE CLICKED");
  };

  const duration = moment.duration(taskGroup.completionWindow, "seconds");
  let durationString = "";
  if (duration.days()) {
    durationString = duration.days() + "d ";
  }
  if (duration.hours()) {
    durationString += duration.hours() + "h ";
  }
  if (duration.minutes()) {
    durationString += duration.minutes() + "m ";
  }
  const startDate = moment(taskGroup.allocationStartDate).format("D MMM'YY");
  const endDate = moment(taskGroup.listingAllocationEndDate).format("D MMM'YY");

  return (
    <Fragment>
      <ListItem className="task-item">
        <Box className="task-container">
          <Box>
            <Checkbox
              onClick={handleCheck}
              checked={check}
              color="primary"
              edge="start"
            />
          </Box>
          <Box
            onClick={() => {
              history.push("tasks/" + taskGroup.id + "/sections");
            }}
            className="task-section"
          >
            <Box className="task-details">
              <Box>
                <Box>
                  <Typography variant="h6" color="textPrimary">
                    {taskGroup.title}
                  </Typography>
                </Box>
                <Box className="task-particulars">
                  <Typography variant="body1">£ {taskGroup.amount}</Typography>
                  <Box>
                    <AccessTimeIcon />
                    <Typography variant="body1">{durationString}</Typography>
                  </Box>
                  <Box>
                    <DateRangeIcon />
                    <Typography variant="body1">
                      {startDate + " - " + endDate}
                    </Typography>
                  </Box>
                  <Box>
                    <LocalOfferOutlinedIcon />
                    <Typography variant="body1">
                      {taskGroup.tag.name}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box className="task-action">
              <Typography variant="subtitle2" color="textPrimary">
                {taskGroup.state}
              </Typography>
              <Box className="buttons">
                <IconButton
                  className="delete-btn"
                  onClick={onDelete}
                  edge="end"
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            </Box>
          </Box>
        </Box>
      </ListItem>
      <Divider />
    </Fragment>
  );
}
