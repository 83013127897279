import { makeStyles } from "@material-ui/core";
import { YELLOW_BG } from "../utils/colors";

export const commonStyles = makeStyles((theme) => ({
  centerContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    height: "100vh",
  },
  themeButton: {
    borderRadius: 40,
    textTransform: "none",
    fontSize: 18,
    whiteSpace: "nowrap",
  },
  loginBtnStyle: {
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
    backgroundColor: YELLOW_BG,
    borderRadius: 40,
    padding: "6px 40px",
  },
  iconBtn: {
    padding: 0,
  },
  textfieldStyle: {
    backgroundColor: "white",
    margin: "10px 0px",
  },
  themeIconStye: {
    color: theme.palette.primary.main,
  },
  successIconStyle: {
    color: theme.palette.primary.main,
    fontSize: "15em",
  },
  failureIconStyle: {
    color: theme.palette.error.main,
    fontSize: "15em",
  },
  descStyle: { margin: "4% 0" },
  whiteBoldFont: {
    color: "white",
    fontWeight: "bold !important",
  },
  boldFont: {
    fontWeight: "bold !important",
  },
  orderedLists: {
    "& li": {
      margin: "16px 0px",
      fontSize: "1.5rem",
      fontWeight: "bold",
      "& p": {
        fontWeight: "normal",
        fontSize: "1rem",
      },
      "& ul li": {
        fontWeight: "normal",
        fontSize: "1rem",
      },
    },
  },
  loginLinkStyle: {
    color: "white",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  roundedInputborder: {
    [`& fieldset`]: {
      borderRadius: 10,
    },
  },
  charCountStyle: {
    display: "flex",
    justifyContent: "flex-end",
  },
}));
