import { createMuiTheme } from "@material-ui/core";

const mainGreen = "#4FA873";
const darkGreen = "#15333B";

const theme = createMuiTheme({
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 40,
        textTransform: "none",
        fontSize: 18,
        whiteSpace: "nowrap",
      },
      containedPrimary: {
        "&:hover": {
          color: "#fff",
        },
      },
      containedSecondary: {
        "&:hover": {
          color: darkGreen,
        },
      },
    },
    MuiFormHelperText: {
      root: { color: darkGreen },
    },
    MuiInputLabel: {
      root: {
        color: darkGreen,
      },
    },
    MuiSelect: {
      root: {
        padding: "12px",
      },
    },
    MuiFilledInput: {
      input: {
        padding: "12px 12px 12px",
      },
      adornedEnd: {
        paddingRight: "0px",
      },
    },
    MuiCheckbox: {
      root: {
        color: darkGreen,
      },
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: darkGreen,
        fontSize: "16px",
      },
    },
  },
  palette: {
    primary: {
      main: mainGreen,
      dark: darkGreen,
      contrastText: "#fff",
    },
    secondary: {
      main: "#fff",
      contrastText: darkGreen,
    },
    info: {
      main: "#2880C0",
      contrastText: "#fff",
    },
    text: {
      primary: darkGreen,
      secondary: "#fff",
    },
    error: {
      main: "#E31A3E",
    },
  },
});

export default theme;
