import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import AppDrawer from "../components/AppDrawer";
import FontDownloadIcon from "@material-ui/icons/FontDownload";
import CharCountTextField from "../components/CharCountTextfield";
import DescriptionIcon from "@material-ui/icons/Description";
import { FormBuilderContext } from "../contexts/app_context";
import { DragDropContext } from "react-beautiful-dnd";
import {
  SECTIONS_FORM_PAGE,
  TEXT_CARD,
  NUMBER_CARD,
  RADIO_CARD,
  FORM_DRAGGABLE_ID,
  CHECK_CARD,
  IMAGE_CARD,
  GRID_CARD,
  SLIDER_CARD,
  DATE_TIME_CARD,
  SECTION_CREATION_ERROR,
  URL_BUTTON_CARD,
} from "./../utils/constants";
import ElementDroppables from "../components/form-builder/ElementDroppables";
import Kiosk from "../components/form-builder/Kiosk";
import CustomRichTextEditor from "../components/CustomRichTextEditor";
import { useHistory, useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/react-hooks";
import {
  CREATE_SUBMISSION_FORM,
  EDIT_SUBMISSION_FORM,
} from "../utils/mutations";
import SectionPreviewModal from "../components/form-builder/SectionPreviewModal";
import { GET_SUBMISSION_FORM_IMAGES } from "../utils/queries";
const shortid = require("shortid");

export default function SectionBuilderPage(props) {
  const sectionData = props.location.state;
  let initialQuestions = [];
  if (sectionData) {
    const val = JSON.parse(sectionData.section.template);
    initialQuestions = val.fields.map((item) => {
      return {
        id: shortid.generate(),
        type: item.type,
        onResult: item,
        onError: false,
      };
    });
  }

  const [droppedElements, setDroppedElements] = useState(initialQuestions);
  const [formDetails, setFormDetails] = useState({
    title: sectionData ? sectionData.section.title : "",
    titleError: false,
    desc: sectionData ? sectionData.section.description : "",
    checkIn: sectionData ? sectionData.section.checkin : false,
    formBuilderError: false,
    onSubmissionError: null,
    onSubmissionLoading: false,
    sectionId: sectionData ? sectionData.section.id : null,
    shouldCreateSection: sectionData ? false : true,
  });
  const [showPreview, setShowPreview] = useState(false);

  let { id } = useParams();

  const history = useHistory();

  const [createSubmissionForm] = useMutation(CREATE_SUBMISSION_FORM);
  const [editSubmissionForm] = useMutation(EDIT_SUBMISSION_FORM);

  let attachments = [];

  const attachmentsQuery = useQuery(GET_SUBMISSION_FORM_IMAGES, {
    variables: { submission: formDetails.sectionId },
    skip: formDetails.sectionId ? false : true,
  });
  if (attachmentsQuery.data) {
    attachments = attachmentsQuery.data.submissionAttachments;
  }

  const getRequiredText = (label, type) => {
    return (
      <Typography color="textPrimary" variant={type}>
        {label}
        <abbr className="required">*</abbr>
      </Typography>
    );
  };

  const getDefaultField = (icon, title, field, required = false) => {
    return (
      <Box className="default-field">
        <Box minWidth="160px" mx={1} display="flex" alignItems="center">
          {icon}
          {required ? (
            getRequiredText(title, "h6")
          ) : (
            <Typography variant="h6">{title}</Typography>
          )}
        </Box>
        <Box width="100%">{field}</Box>
      </Box>
    );
  };

  const cards = [
    { id: "card-1", type: TEXT_CARD, onResult: null, onError: false },
    { id: "card-2", type: NUMBER_CARD, onResult: null, onError: false },
    { id: "card-3", type: RADIO_CARD, onResult: null, onError: false },
    { id: "card-4", type: CHECK_CARD, onResult: null, onError: false },
    { id: "card-5", type: GRID_CARD, onResult: null, onError: false },
    { id: "card-6", type: IMAGE_CARD, onResult: null, onError: false },
    { id: "card-7", type: DATE_TIME_CARD, onResult: null, onError: false },
    { id: "card-8", type: SLIDER_CARD, onResult: null, onError: false },
    { id: "card-9", type: URL_BUTTON_CARD, onResult: null, onError: false },
  ];

  const onDropped = (result) => {
    const { source, destination } = result;
    if (!destination) {
      return;
    }

    switch (source.droppableId) {
      case destination.droppableId:
        setDroppedElements((state) =>
          reorder(state, source.index, destination.index)
        );
        break;
      case FORM_DRAGGABLE_ID:
        setDroppedElements((state) => copy(cards, state, source, destination));
        break;
      default:
        break;
    }
  };

  const reorder = (list, startIndex, endIndex) => {
    const [removed] = list.splice(startIndex, 1);
    list.splice(endIndex, 0, removed);
    return list;
  };

  const copy = (source, elements, draggableSource, droppableDestination) => {
    const item = source[draggableSource.index];
    elements.splice(droppableDestination.index, 0, {
      ...item,
      id: shortid.generate(),
    });
    return elements;
  };

  const envelopItemState = {
    updateItem: (result) => {
      droppedElements.find((item, index) => {
        if (item.id === result.item.id) {
          const elements = droppedElements;
          elements[index] = {
            ...result.item,
            onResult: result.data,
            onError: false,
          };
          if (result.data) {
            updateSection(elements);
          }
          setDroppedElements([...elements]);
          return true;
        }
        return false;
      });
    },
    removeItem: (pos) => {
      droppedElements.splice(pos, 1);
      updateSection(droppedElements);
      setDroppedElements([...droppedElements]);
    },
    sectionId: formDetails.sectionId,
    sectionAttachments: attachments,
    refetchAttachments: () => {
      attachmentsQuery.refetch();
    },
  };

  const onSubmit = () => {
    if (validateForm()) {
      setFormDetails((prevState) => ({
        ...prevState,
        onSubmissionError: null,
        onSubmissionLoading: true,
      }));
      const collectedData = droppedElements.map((card) => {
        return card.onResult;
      });
      const templateData = { fields: collectedData };
      const jsonData = JSON.stringify(templateData);

      let submissionData = {
        taskGroup: id,
        title: formDetails.title,
        description: formDetails.desc,
        checkin: formDetails.checkIn,
        template: jsonData,
      };

      if (formDetails.sectionId) {
        submissionData = {
          ...submissionData,
          submission: formDetails.sectionId,
        };
        editSubmissionForm({
          variables: { data: submissionData },
        })
          .then(() => {
            history.goBack();
          })
          .catch((e) => {
            setFormDetails((prevState) => ({
              ...prevState,
              onSubmissionError: e.graphQLErrors[0].message,
              onSubmissionLoading: false,
            }));
          });
      } else if (formDetails.shouldCreateSection) {
        setFormDetails((prevState) => ({
          ...prevState,
          shouldCreateSection: false,
        }));
        createSubmissionForm({
          variables: { data: submissionData },
        })
          .then((result) => {
            if (result.data.createSubmission.submission.id) {
              history.goBack();
            } else {
              setFormDetails((prevState) => ({
                ...prevState,
                onSubmissionError: SECTION_CREATION_ERROR,
                onSubmissionLoading: false,
              }));
            }
          })
          .catch((error) => {
            setFormDetails((prevState) => ({
              ...prevState,
              onSubmissionError: error.graphQLErrors[0].message,
              onSubmissionLoading: false,
              shouldCreateSection: true,
            }));
          });
      } else {
        setFormDetails((prevState) => ({
          ...prevState,
          onSubmissionLoading: false,
        }));
      }
    }
  };

  //silent save
  const updateSection = (elements) => {
    if (elements.length) {
      const templateData = [];
      elements.forEach((card) => {
        if (card.onResult) {
          templateData.push(card.onResult);
        }
      });
      if (templateData.length) {
        const jsonData = JSON.stringify({ fields: templateData });

        let submissionData = {
          taskGroup: id,
          title: formDetails.title ? formDetails.title : "Untitled",
          description: formDetails.desc,
          checkin: formDetails.checkIn,
          template: jsonData,
        };

        if (formDetails.sectionId) {
          submissionData = {
            ...submissionData,
            submission: formDetails.sectionId,
          };
          editSubmissionForm({
            variables: { data: submissionData },
          })
            .then((result) => {
              console.log("SECTION UPDATED" + result);
            })
            .catch((e) => {
              console.log("Error while updating section: " + e);
            });
        } else if (formDetails.shouldCreateSection) {
          setFormDetails((prevState) => ({
            ...prevState,
            shouldCreateSection: false,
          }));
          createSubmissionForm({
            variables: { data: submissionData },
          })
            .then((result) => {
              const submissionId = result.data.createSubmission.submission.id;
              if (submissionId) {
                setFormDetails((ps) => ({ ...ps, sectionId: submissionId }));
              }
            })
            .catch((e) => {
              setFormDetails((prevState) => ({
                ...prevState,
                shouldCreateSection: true,
              }));
            });
        }
      }
    }
  };

  const validateForm = () => {
    const { title } = formDetails;
    const titleStatus = title.trim().length === 0;
    let formBuilderStatus = false;
    let formElementStatus;
    if (droppedElements.length) {
      formElementStatus = droppedElements.some((card) => {
        if (!card.onResult) {
          setDroppableError(card);
          return true;
        } else {
          return false;
        }
      });
    } else {
      formBuilderStatus = true;
    }

    setFormDetails((prevState) => ({
      ...prevState,
      titleError: titleStatus,
      formBuilderError: formBuilderStatus,
    }));

    return !titleStatus && !formBuilderStatus && !formElementStatus;
  };

  const setDroppableError = (card) => {
    droppedElements.find((item, index) => {
      if (item.id === card.id) {
        const elements = droppedElements;
        elements[index] = { ...card, onError: true };
        setDroppedElements([...elements]);
        return true;
      } else {
        return false;
      }
    });
  };

  return (
    <AppDrawer pageName={SECTIONS_FORM_PAGE}>
      <Box my={1}>
        <FormBuilderContext.Provider value={envelopItemState}>
          <Box my={2} className="page-bar">
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="h5" color="textSecondary">
                Section form builder
              </Typography>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  setShowPreview(true);
                }}
              >
                Preview
              </Button>
              <SectionPreviewModal
                showModal={showPreview}
                onClose={() => {
                  setShowPreview(false);
                }}
                content={{
                  title: formDetails.title,
                  description: formDetails.desc,
                  questions: droppedElements,
                }}
              />
            </Box>
          </Box>

          <DragDropContext onDragEnd={onDropped}>
            <Box className="section-builder-container">
              <Box className="draggable">
                <Kiosk cards={cards} />
              </Box>
              <Box className="droppable">
                <Box className="form-container">
                  {getRequiredText(
                    "Required fields are marked with an asterisk",
                    "subtitle1"
                  )}
                  <Box className="fields-container">
                    {getDefaultField(
                      <FontDownloadIcon />,
                      "Title",
                      <CharCountTextField
                        charLimit={200}
                        label="Please enter a title for your section"
                        onComplete={(value) => {
                          setFormDetails((prevState) => ({
                            ...prevState,
                            title: value,
                          }));
                        }}
                        onError={formDetails.titleError}
                        shouldFocus={true}
                        defaultValue={formDetails.title}
                      />,
                      true
                    )}
                    {getDefaultField(
                      <DescriptionIcon />,
                      "Description",
                      <CustomRichTextEditor
                        label="Please describe more about your section here..."
                        onComplete={(content) => {
                          console.log("Section description: " + content);
                          //TODO: Issue with empty value from the Richtexteditors
                          formDetails.desc = content;
                        }}
                        defaultValue={formDetails.desc}
                      />
                    )}
                  </Box>
                  <ElementDroppables elements={droppedElements} />
                </Box>
                <Box className="form-action-container">
                  {formDetails.formBuilderError && (
                    <Typography variant="subtitle1" color="error">
                      Please add a minimum of 1 question.
                    </Typography>
                  )}
                  <Box display="flex" alignItems="center">
                    <Checkbox
                      name="checkIn"
                      checked={formDetails.checkIn}
                      onChange={(e) =>
                        setFormDetails((prevState) => ({
                          ...prevState,
                          checkIn: e.target.checked,
                        }))
                      }
                      color="primary"
                    />
                    <Typography color="textPrimary" variant="subtitle1">
                      I also need user location with this form.
                    </Typography>
                  </Box>
                  <Box my={1}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => onSubmit()}
                    >
                      {formDetails.onSubmissionLoading ? (
                        <CircularProgress color="secondary" />
                      ) : formDetails.sectionId ? (
                        "Continue"
                      ) : (
                        "Save Section"
                      )}
                    </Button>
                    {formDetails.onSubmissionError && (
                      <Typography variant="subtitle1" color="error">
                        {formDetails.onSubmissionError}
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          </DragDropContext>
        </FormBuilderContext.Provider>
      </Box>
    </AppDrawer>
  );
}
