import React, { useContext, useEffect, useState } from "react";
import { Box, Typography, Switch } from "@material-ui/core";
import { FormBuilderContext } from "../../../../contexts/app_context";
import {
  getQuestionImageSection,
  getQuestionTextfield,
  getRequiredText,
} from "../../../../utils/utilities";
import CustomTooltip from "../../../CustomToolTip";
import getFormbuilderIcon from "../../../../utils/custom-icons";
import { useMutation } from "@apollo/react-hooks";
import {
  DELETE_SECTION_IMAGE,
  UPLOAD_SECTION_IMAGE,
} from "../../../../utils/mutations";
import SliderValue from "./SliderValue";

export default function SliderTypeField({ element }) {
  const itemContext = useContext(FormBuilderContext);
  const { onResult } = element;
  const [fields, setFields] = useState({
    label: onResult ? onResult.label : "",
    labelError: element.onError,
    required: onResult ? onResult.required : false,
    image: null,
    start: onResult ? onResult.range.min : "",
    startError: false,
    end: onResult ? onResult.range.max : null,
    endError: false,
    imageId: onResult ? onResult.image : null,
  });

  const [uploadImage] = useMutation(UPLOAD_SECTION_IMAGE);
  const [deleteImage] = useMutation(DELETE_SECTION_IMAGE);

  const setLabel = (event) => {
    const value = event.target.value.trim();

    setFields((prevState) => ({
      ...prevState,
      label: value,
      labelError: value ? false : true,
    }));
  };

  const onImagePicked = (e) => {
    const files = e.target.files;
    const [src] = files;
    setFields((prevState) => ({
      ...prevState,
      image: src,
    }));
    if (itemContext.sectionId) {
      uploadImage({
        variables: {
          image1: src,
          responseTemplate: false,
          submission: itemContext.sectionId,
        },
      }).then((result) => {
        if (result.data.uploadSubmissionImages.imageIds.length) {
          const id = result.data.uploadSubmissionImages.imageIds[0];
          setFields((ps) => ({ ...ps, imageId: id }));
          itemContext.refetchAttachments();
        }
      });
    }
  };

  const onImageDelete = () => {
    if (fields.imageId) {
      deleteImage({ variables: { imageId: fields.imageId } });
    }
    setFields((prevState) => ({
      ...prevState,
      image: null,
      imageId: null,
    }));
  };

  const setStartValue = (value) => {
    setFields((prevState) => ({
      ...prevState,
      start: value,
      startError: value ? false : true,
    }));
  };

  const setEndValue = (value) => {
    setFields((prevState) => ({
      ...prevState,
      end: value,
      endError: value ? false : true,
    }));
  };

  useEffect(() => {
    if (fields.label && fields.start && fields.end) {
      let min = fields.start;
      let max = fields.end;
      if (fields.start > fields.end) {
        min = fields.end;
        max = fields.start;
      }
      itemContext.updateItem({
        item: element,
        data: {
          type: element.type,
          label: fields.label,
          range: { min: min, max: max },
          required: fields.required,
          response: null,
          image: fields.imageId,
        },
      });
    } else {
      itemContext.updateItem({
        item: element,
        data: null,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields]);

  return (
    <Box className="question-container">
      <Box className="item-icon-style">{getFormbuilderIcon(element.type)}</Box>
      <Box className="question-fields">
        <Box>
          {getRequiredText("Enter your question", "h6")}
          {getQuestionTextfield(
            fields.label,
            fields.labelError,
            setLabel,
            "Please describe the question for the range slider here..."
          )}
        </Box>
        <Box className="add-image">
          {getQuestionImageSection(
            fields.image,
            fields.imageId,
            itemContext.sectionAttachments,
            onImagePicked,
            onImageDelete,
            element.id
          )}
        </Box>
        <Box className="slider-options">
          <Box>
            {getRequiredText("Start", "h6")}
            <CustomTooltip content="Starting value for the slider." />
          </Box>
          <SliderValue
            placeholder="E.g. 10"
            onError={fields.startError}
            defaultValue={fields.start}
            onComplete={setStartValue}
          />
        </Box>
        <Box className="slider-options">
          <Box>
            {getRequiredText("End", "h6")}
            <CustomTooltip content="End value for the slider." />
          </Box>
          <SliderValue
            placeholder="E.g. 100"
            onError={fields.endError}
            defaultValue={fields.end}
            onComplete={setEndValue}
          />
        </Box>
        <Box className="required-switch">
          <Box>
            <Typography color="textPrimary" variant="h6">
              Required
            </Typography>
            <CustomTooltip content="Don't allow to submit the form, if this question is not answered." />
          </Box>
          <Switch
            checked={fields.required}
            onChange={(e) => {
              setFields((prevState) => ({
                ...prevState,
                required: e.target.checked,
              }));
            }}
            color="primary"
          />
        </Box>
      </Box>
    </Box>
  );
}
