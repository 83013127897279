import React, { useState } from "react";
import { commonStyles } from "../styles/material-styles";
import {
  Box,
  Typography,
  CircularProgress,
  TextField,
  Button,
} from "@material-ui/core";
import { useMutation } from "@apollo/react-hooks";
import { RESET_PASSWORD } from "../utils/mutations";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import { validatePassword } from "../utils/utilities";
import { Redirect } from "react-router-dom";

export default function PasswordReset(props) {
  const common = commonStyles();

  const [resetPassword] = useMutation(RESET_PASSWORD);

  const [state, setState] = useState({
    loading: false,
    complete: false,
    result: false,
    p1: "",
    p2: "",
    p1Error: false,
    p2Error: false,
  });

  const url = new URL(window.location.href);
  const token = url.searchParams.get("reset");
  if (!token) {
    return <Redirect to={{ pathname: "/page-not-found" }} />;
  }

  const handleChange = (e) => {
    const { id, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [id]: validatePassword(value.trim()) ? value.trim() : "",
    }));
  };

  const onSumit = (e) => {
    e.preventDefault();
    if (state.p1 && state.p2 && state.p1 === state.p2) {
      setState((prevState) => ({
        ...prevState,
        loading: true,
        p1Error: false,
        p2Error: false,
      }));
      if (token) {
        resetPassword({
          variables: { newPassword: state.p1, resetToken: token },
          context: {
            headers: {
              unprotected: true,
            },
          },
        })
          .then((result) => {
            let status = false;
            if (result.data.resetPassword.success) {
              status = true;
            }
            setState((prevState) => ({
              ...prevState,
              loading: false,
              complete: true,
              result: status,
            }));
          })
          .catch((error) => {
            console.log("On Error: " + error);
            setState((prevState) => ({
              ...prevState,
              loading: false,
              complete: true,
              result: false,
            }));
          });
      }
    } else {
      setState((prevState) => ({
        ...prevState,
        p1Error: prevState.p1 === "",
        p2Error: prevState.p1 !== prevState.p2,
      }));
    }
  };

  if (state.complete) {
    return (
      <Box className={common.centerContainer}>
        {state.result ? (
          <CheckCircleIcon className={common.successIconStyle} />
        ) : (
          <CancelIcon className={common.failureIconStyle} />
        )}
        <Typography
          style={{ fontWeight: "bold" }}
          color="textPrimary"
          variant="h3"
          align="center"
        >
          {state.result ? "Password Reset Successful" : "Password Reset Failed"}
        </Typography>
        <Typography
          className={common.descStyle}
          color="textPrimary"
          variant="h6"
          align="center"
        >
          {state.result
            ? "Your password has been reset successfully."
            : "We couldn't reset your password at the moment."}
        </Typography>
      </Box>
    );
  } else {
    return (
      <Box className={common.centerContainer}>
        <Typography
          style={{ fontWeight: "bold" }}
          color="textPrimary"
          variant="h4"
          align="center"
        >
          Reset your password
        </Typography>
        <Box mx={2}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="New password"
            type="password"
            id="p1"
            onChange={(e) => handleChange(e)}
            error={state.p1Error}
            helperText={state.p1Error && "Please enter a valid password"}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Re-type password"
            type="password"
            id="p2"
            onChange={(e) => handleChange(e)}
            error={state.p2Error}
            helperText={state.p2Error && "Password doesn't match"}
          />
        </Box>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          onClick={onSumit}
        >
          {state.loading ? (
            <CircularProgress color="inherit" />
          ) : (
            "Reset Password"
          )}
        </Button>
      </Box>
    );
  }
}
