import React from "react";

export const contextState = {
  user: null,
  setUser: (val) => {
    contextState.user = val;
  },
  keys: {
    stripe: "",
  },
  setKeys: (value) => {
    contextState.keys = value;
  },
  refetchSection: false,
  shouldShowSectionActions: true,
};

export const AppContext = React.createContext();

export const AppProvider = AppContext.Provider;
export const AppConsumer = AppContext.Consumer;

export const FormBuilderContext = React.createContext();

export const ImportSectionContext = React.createContext();
