import React, { Fragment, useState, useContext, useEffect } from "react";
import { Box, Button } from "@material-ui/core";
import ValuesTextField from "./ValuesTextField";
import { FormBuilderContext } from "../../../contexts/app_context";
import {
  getQuestionImageSection,
  getQuestionTextfield,
  getRequiredText,
} from "../../../utils/utilities";
import getFormbuilderIcon from "../../../utils/custom-icons";
import { useMutation } from "@apollo/react-hooks";
import {
  DELETE_SECTION_IMAGE,
  UPLOAD_SECTION_IMAGE,
} from "../../../utils/mutations";
const shortid = require("shortid");

export default function CheckTypeField({ element }) {
  const itemContext = useContext(FormBuilderContext);
  let defaultValues = [];
  if (element.onResult) {
    for (const [key] of Object.entries(element.onResult.values)) {
      defaultValues.push(key);
    }
  }
  const [values, setValues] = useState(defaultValues);
  const [fields, setFields] = useState({
    label: element.onResult ? element.onResult.label : "",
    labelError: false,
    image: null,
    imageId: element.onResult ? element.onResult.image : null,
    shouldAdd: false,
  });

  const [uploadImage] = useMutation(UPLOAD_SECTION_IMAGE);
  const [deleteImage] = useMutation(DELETE_SECTION_IMAGE);

  const valueFields = () => {
    if (values.length) {
      let elements = values.map((item, index) => (
        <ValuesTextField
          key={item}
          onValue={setCheckValue}
          onRemove={removeCheckValue}
          index={index}
          item={item}
          showDelete={true}
          type={element.type}
        />
      ));
      if (values.length < 1) {
        elements.push(
          <ValuesTextField
            key={shortid.generate()}
            onValue={setCheckValue}
            index={values.length}
            showDelete={false}
            type={element.type}
            shouldFocus={true}
          />
        );
      }

      if (fields.shouldAdd) {
        elements.push(
          <ValuesTextField
            key={shortid.generate()}
            onValue={setCheckValue}
            index={values.length + 1}
            type={element.type}
            shouldFocus={true}
          />
        );
      }
      return <Fragment>{elements}</Fragment>;
    } else {
      return (
        <>
          <ValuesTextField
            onValue={setCheckValue}
            index={values.length}
            allowAdd={true}
            type={element.type}
          />
        </>
      );
    }
  };

  const setShouldAdd = (value) => {
    setFields((ps) => ({ ...ps, shouldAdd: value }));
  };

  const addOption = () => {
    if (values.length >= 1 && values.length < 10) {
      setShouldAdd(true);
    }
  };

  const setCheckValue = (data) => {
    const index = data.index > values.length ? values.length : data.index;
    setValues((prevState) => {
      prevState[index] = data.value;
      return [...prevState];
    });
    setShouldAdd(false);
  };

  const removeCheckValue = (pos) => {
    setValues((prevState) => {
      prevState.splice(pos, 1);
      return [...prevState];
    });
  };

  const setLabel = (event) => {
    const value = event.target.value.trim();

    setFields((prevState) => ({
      ...prevState,
      label: value,
      labelError: value ? false : true,
    }));
  };

  const onImagePicked = (e) => {
    const files = e.target.files;
    const [src] = files;
    setFields((prevState) => ({
      ...prevState,
      image: src,
    }));
    if (itemContext.sectionId)
      uploadImage({
        variables: {
          image1: src,
          responseTemplate: false,
          submission: itemContext.sectionId,
        },
      }).then((result) => {
        if (result.data.uploadSubmissionImages.imageIds.length) {
          const id = result.data.uploadSubmissionImages.imageIds[0];
          setFields((ps) => ({ ...ps, imageId: id }));
          itemContext.refetchAttachments();
        }
      });
  };

  const onImageDelete = () => {
    if (fields.imageId) {
      deleteImage({ variables: { imageId: fields.imageId } });
    }
    setFields((prevState) => ({
      ...prevState,
      image: null,
      imageId: null,
    }));
  };

  useEffect(() => {
    if (fields.label && values.length) {
      let valueObj = {};
      values.forEach((item) => {
        valueObj[item] = false;
      });

      itemContext.updateItem({
        item: element,
        data: {
          type: element.type,
          label: fields.label,
          values: valueObj,
          image: fields.imageId,
        },
      });
    } else {
      itemContext.updateItem({
        item: element,
        data: null,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields]);

  return (
    <Box className="question-container">
      <Box className="item-icon-style">{getFormbuilderIcon(element.type)}</Box>
      <Box className="question-fields">
        <Box>
          {getRequiredText("Enter your question", "h6")}
          {getQuestionTextfield(
            fields.label,
            fields.labelError,
            setLabel,
            "Please describe the question for the multiple choice options here..."
          )}
        </Box>
        <Box className="add-image">
          {getQuestionImageSection(
            fields.image,
            fields.imageId,
            itemContext.sectionAttachments,
            onImagePicked,
            onImageDelete,
            element.id
          )}
        </Box>
        <Box className="options-container">
          <Box>{valueFields}</Box>
          <Button onClick={addOption}>Add option</Button>
        </Box>
      </Box>
    </Box>
  );
}
