import {
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  IconButton,
  CircularProgress,
  Grid,
} from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SaveAltIcon from "@material-ui/icons/SaveAlt";
import CloseIcon from "@material-ui/icons/Close";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { GET_TASK_GROUPS } from "../../utils/queries";
import TaskTile from "./TaskTile";
import { AppContext, ImportSectionContext } from "../../contexts/app_context";
import { IMPORT_SECTION } from "../../utils/mutations";
import { useParams } from "react-router-dom";

export default function ImportSectionModal({ showModal, onClose }) {
  const [open, setOpen] = useState(showModal);
  const { loading, data } = useQuery(GET_TASK_GROUPS);
  const [sections, setSections] = useState([]);
  const checkedSections = { sections, setSections };
  const [importSection, setImportSection] = useState({
    loading: false,
    error: null,
  });

  const id = parseInt(useParams().id);
  const [importSectionApi] = useMutation(IMPORT_SECTION);
  const appState = useContext(AppContext);

  useEffect(() => {
    if (showModal) {
      handleClickOpen();
    }
  }, [showModal]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSections([]);
    onClose();
  };

  const getAccordion = (taskId, title) => {
    return (
      <Accordion key={taskId}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6" color="textPrimary">
            {title}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TaskTile taskId={taskId} />
        </AccordionDetails>
      </Accordion>
    );
  };
  let content;
  if (loading) {
    content = (
      <Grid container justify="center">
        <CircularProgress />
      </Grid>
    );
  } else if (data && data.allTaskGroups.length) {
    content = data.allTaskGroups.flatMap((item) =>
      item.id === id ? [] : getAccordion(item.id, item.title)
    );
  } else {
    content = (
      <Typography variant="h6" color="textPrimary">
        No tasks available to import.
      </Typography>
    );
  }

  const handleImport = () => {
    if (sections.length) {
      setImportSection({
        loading: true,
        error: null,
      });
      importSectionApi({
        variables: {
          data: {
            taskGroup: id,
            submissions: sections,
          },
        },
      })
        .then((result) => {
          setImportSection((ps) => ({
            ...ps,
            loading: false,
          }));
          if (result.data.bulkCloneSubmissions.success) {
            appState.refetchSection = true;
            handleClose();
          }
        })
        .catch((error) => {
          setImportSection({
            loading: false,
            error: error.message,
          });
        });
    } else {
      setImportSection((ps) => ({
        ...ps,
        error: "Please select a section to import.",
      }));
    }
  };

  return (
    <ImportSectionContext.Provider value={checkedSections}>
      <Dialog onClose={handleClose} open={open} className="section-dialog">
        <Box className="title">
          <Typography variant="h6" color="textPrimary">
            Import from tasks
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <DialogContent dividers>{content}</DialogContent>
        <DialogActions className="import-section-action">
          <Box mx={2}>
            <Typography variant="subtitle1" color="textPrimary">
              {sections.length} Selected item(s)
            </Typography>
          </Box>
          <Box display="flex" flexDirection="column" alignItems="flex-end">
            <Box>
              <Button
                startIcon={<SaveAltIcon style={{ fontSize: "x-large" }} />}
                onClick={handleImport}
                variant="contained"
                color="primary"
              >
                {importSection.loading ? (
                  <CircularProgress color="secondary" />
                ) : (
                  "Import"
                )}
              </Button>
            </Box>
            {importSection.error && (
              <Typography color="error" variant="subtitle2">
                {importSection.error}
              </Typography>
            )}
          </Box>
        </DialogActions>
      </Dialog>
    </ImportSectionContext.Provider>
  );
}
