import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Button,
  Box,
  TextField,
  Slider,
} from "@material-ui/core";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import React, { useContext, useEffect, useState } from "react";
import {
  CHECK_CARD,
  DATE_TIME_CARD,
  GRID_CARD,
  IMAGE_CARD,
  NUMBER_CARD,
  RADIO_CARD,
  SLIDER_CARD,
  TEXT_CARD,
  URL_BUTTON_CARD,
} from "../../utils/constants";
import ImageIcon from "@material-ui/icons/Image";
import { getRequiredText } from "../../utils/utilities";
import { FormBuilderContext } from "../../contexts/app_context";

export default function SectionPreviewModal({ showModal, onClose, content }) {
  const [open, setOpen] = useState(showModal);
  const itemContext = useContext(FormBuilderContext);

  useEffect(() => {
    if (showModal) {
      handleClickOpen();
    }
  }, [showModal]);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    onClose();
  };

  const getField = (title, required, fields) => {
    return (
      <Box key={title} className="container">
        {required ? (
          getRequiredText(title, "subtitle1")
        ) : (
          <Typography variant="subtitle1" color="textPrimary">
            {title}
          </Typography>
        )}
        {fields}
      </Box>
    );
  };

  const getImage = (id) => {
    const url = itemContext.sectionAttachments.find((item) => item.id === id);
    if (url) {
      return <img src={url.attachment} alt="" width={200} height={150} />;
    }
    return <></>;
  };

  const getQuestionFields = () => {
    if (content.questions) {
      return content.questions.map((element) => {
        if (element.onResult) {
          switch (element.onResult.type) {
            case TEXT_CARD:
              return getField(
                element.onResult.label,
                element.onResult.required,
                <Box>
                  {element.onResult.image && getImage(element.onResult.image)}
                  <TextField
                    variant="filled"
                    disabled
                    placeholder="Your response would appear here."
                  />
                </Box>
              );
            case NUMBER_CARD:
              return getField(
                element.onResult.label,
                element.onResult.required,
                <Box>
                  {element.onResult.image && getImage(element.onResult.image)}
                  <TextField variant="filled" disabled placeholder="Eg. 2" />
                </Box>
              );
            case RADIO_CARD:
              const radioValues = [];
              for (const [key] of Object.entries(element.onResult.values)) {
                radioValues.push(
                  <Box key={key} className="options-preview">
                    <RadioButtonUncheckedIcon />
                    <Typography color="textPrimary" variant="subtitle1">
                      {key}
                    </Typography>
                  </Box>
                );
              }
              return getField(
                element.onResult.label,
                element.onResult.required,
                <Box>
                  {element.onResult.image && getImage(element.onResult.image)}
                  {radioValues}
                </Box>
              );

            case CHECK_CARD:
              const checkValues = [];
              for (const [key] of Object.entries(element.onResult.values)) {
                checkValues.push(
                  <Box key={key} className="options-preview">
                    <CheckBoxOutlineBlankIcon />
                    <Typography color="textPrimary" variant="subtitle1">
                      {key}
                    </Typography>
                  </Box>
                );
              }
              return getField(
                element.onResult.label,
                element.onResult.required,
                <Box>
                  {element.onResult.image && getImage(element.onResult.image)}
                  {checkValues}
                </Box>
              );
            case IMAGE_CARD:
              return getField(
                element.onResult.label,
                element.onResult.required,
                <Box className="image-preview">
                  <ImageIcon />
                  <Typography variant="subtitle2" color="textPrimary">
                    {"x " + element.onResult.count}
                  </Typography>
                </Box>
              );
            case DATE_TIME_CARD:
              return getField(
                element.onResult.label,
                element.onResult.required,
                <Box>
                  {element.onResult.image && getImage(element.onResult.image)}
                  <TextField
                    variant="filled"
                    disabled
                    placeholder="DD/MM/YYYY"
                  />
                </Box>
              );
            case SLIDER_CARD:
              return getField(
                element.onResult.label,
                element.onResult.required,
                <Box>
                  {element.onResult.image && getImage(element.onResult.image)}
                  <Box className="slider-preview">
                    <Box className="range">
                      <Typography variant="subtitle1" color="textPrimary">
                        {element.onResult.range.min}
                      </Typography>
                      <Slider disabled defaultValue={0} />
                      <Typography variant="subtitle1" color="textPrimary">
                        {element.onResult.range.max}
                      </Typography>
                    </Box>
                    <TextField variant="filled" disabled placeholder="E.g. 0" />
                  </Box>
                </Box>
              );
            case GRID_CARD:
              const rowValues = [];
              const colValues = [];
              element.onResult.rowValues.forEach((item) => {
                rowValues.push(
                  <Typography
                    key={item}
                    variant="subtitle1"
                    color="textPrimary"
                  >
                    {item}
                  </Typography>
                );
              });
              element.onResult.colValues.forEach((item) => {
                colValues.push(
                  <Typography
                    key={item}
                    variant="subtitle1"
                    color="textPrimary"
                  >
                    {item}
                  </Typography>
                );
              });
              return getField(
                element.onResult.label,
                false,
                <Box className="grid-preview">
                  <Box>
                    <Typography variant="h6" color="textPrimary">
                      Row
                    </Typography>
                    {rowValues}
                  </Box>
                  <Box>
                    <Typography variant="h6" color="textPrimary">
                      Column
                    </Typography>
                    {colValues}
                  </Box>
                </Box>
              );
            case URL_BUTTON_CARD:
              return getField(
                <Box>
                  <Button variant="contained">{element.onResult.label}</Button>
                </Box>
              );
            default:
              return <Box />;
          }
        } else {
          return <Box />;
        }
      });
    }
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle onClose={handleClose}>Preview</DialogTitle>
      <DialogContent dividers className="section-preview">
        <Typography className="title" variant="h6" color="textPrimary">
          {content.title}
        </Typography>
        <Typography variant="subtitle1" color="textPrimary">
          {content.description}
        </Typography>
        <Box>{getQuestionFields}</Box>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
