import React, { useContext } from "react";
import { AppContext } from "../contexts/app_context";
import AppDrawer from "../components/AppDrawer";
import { Box, Button, Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import { HOME_PAGE } from "../utils/constants";
import AddIcon from "@material-ui/icons/Add";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import TasksList from "./../components/tasks-list/TasksList";
import { useQuery } from "@apollo/react-hooks";
import { GET_CLIENT_KEYS } from "../utils/queries";

function HomePage() {
  const appState = useContext(AppContext);

  useQuery(GET_CLIENT_KEYS, {
    onCompleted: (data) => {
      if (data.clientKeys.stripe) {
        appState.setKeys({ stripe: data.clientKeys.stripe });
      }
    },
  });

  return (
    <AppDrawer pageName={HOME_PAGE}>
      <h1>Hello {appState.user.firstName}</h1>

      <Box m={1}>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Button
            component={Link}
            to="/tasks/new"
            variant="contained"
            color="primary"
            startIcon={<AddIcon style={{ fontSize: "x-large" }} />}
          >
            Create Task
          </Button>
          <Button
            variant="contained"
            color="primary"
            startIcon={<FileCopyIcon />}
          >
            Clone Task
          </Button>
        </Grid>
      </Box>
      <TasksList />
    </AppDrawer>
  );
}

export default HomePage;
