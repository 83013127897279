import React, { useContext, useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import { FormBuilderContext } from "../../../contexts/app_context";
import {
  getQuestionTextfield,
  getRequiredText,
} from "../../../utils/utilities";
import getFormbuilderIcon from "../../../utils/custom-icons";

export default function UrlButtonTypeField({ element }) {
  const itemContext = useContext(FormBuilderContext);

  const [fields, setFields] = useState({
    label: element.onResult ? element.onResult.label : "",
    labelError: element.onError,
    url: element.onResult ? element.onResult.url : "",
    urlError: element.onError,
  });

  const setLabel = (event) => {
    const value = event.target.value.trim();

    setFields((prevState) => ({
      ...prevState,
      label: value,
      labelError: value ? false : true,
    }));
  };

  const setUrl = (event) => {
    const value = event.target.value.trim();

    setFields((prevState) => ({
      ...prevState,
      url: value,
      urlError: value ? false : true,
    }));
  };

  useEffect(() => {
    if (fields.label) {
      itemContext.updateItem({
        item: element,
        data: {
          type: element.type,
          label: fields.label,
          url: fields.url,
          response: null,
        },
      });
    } else {
      itemContext.updateItem({ item: element, data: null });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields]);

  return (
    <Box className="question-container">
      <Box className="item-icon-style">{getFormbuilderIcon(element.type)}</Box>
      <Box className="question-fields">
        <Box>
          {getRequiredText("Enter your button text", "h6")}
          {getQuestionTextfield(
            fields.label,
            fields.labelError,
            setLabel,
            "Please describe the text for the button..."
          )}
        </Box>
        <Box>
          {getRequiredText("Enter your url", "h6")}
          {getQuestionTextfield(
            fields.url,
            fields.urlError,
            setUrl,
            "Please describe the link for the button..."
          )}
        </Box>
      </Box>
    </Box>
  );
}
