import React, { useState, useContext } from "react";
import MenuIcon from "@material-ui/icons/Menu";
import {
  makeStyles,
  AppBar,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  IconButton,
  Box,
  Hidden,
  Link,
  Avatar,
  Button,
  Menu,
  MenuItem,
} from "@material-ui/core";
import web_logo from "../images/web_logo.png";
import { APPBAR_BG, GREY_BG } from "../utils/colors";
import { FAQIcon } from "../utils/custom-icons";
import CachedIcon from "@material-ui/icons/Cached";
import RestoreIcon from "@material-ui/icons/Restore";
import { useHistory } from "react-router-dom";
import {
  CREATE_TASK_PAGE,
  HOME_PAGE,
  REFRESH_TOKEN,
} from "./../utils/constants";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { GET_CURRENT_USER } from "./../utils/queries";
import { REVOKE_TOKEN } from "../utils/mutations";
import HomeIcon from "@material-ui/icons/Home";
import { AppContext } from "../contexts/app_context";
import AcceptCookie from "./AcceptCookie";

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    zIndex: 1,
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    background: APPBAR_BG,
  },
  appBarContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
    color: "white",
  },
  anchor: {
    cursor: "pointer",
  },
  toolbar: {
    minHeight: "80px",
  },
  drawerPaper: {
    width: drawerWidth,
    background:
      "linear-gradient(to right, " + APPBAR_BG + " 30%, " + GREY_BG + " 0%)",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  listStyle: {
    marginTop: 12,
  },
  listItemStyle: {
    padding: "8px 24px",
  },
  listItemIconStyle: {
    display: "block",
  },
  linkStyle: {
    fontWeight: "bold",
  },
  navIconStyle: { color: "white", fontSize: 28 },
  accIcon: { fontSize: 60 },
}));

function AppDrawer(props) {
  const classes = useStyles();
  const [mobileMode, setMobileMode] = useState(false);
  const [profileAnchor, setprofileAnchor] = React.useState(null);
  const history = useHistory();
  const { client, data } = useQuery(GET_CURRENT_USER);
  const [revokeUserToken] = useMutation(REVOKE_TOKEN);

  const appState = useContext(AppContext);

  const toggleDrawer = () => setMobileMode(!mobileMode);

  const handleNavigation = (linkName) => {
    switch (linkName) {
      case HOME_PAGE:
        if (linkName !== props.pageName) {
          history.push("/");
        }
        break;
      case CREATE_TASK_PAGE:
        if (linkName !== props.pageName) {
          history.push("/tasks/new");
        }
        break;
      default:
    }
  };

  const onProfileAvatarClick = (event) => {
    setprofileAnchor(event.currentTarget);
  };

  const closeProfileMenu = () => {
    setprofileAnchor(null);
  };

  const onLogOut = () => {
    if (data) {
      revokeUserToken({
        variables: { token: window.localStorage.getItem(REFRESH_TOKEN) },
      }).catch((err) => {
        console.log("On Revoke Token Error: " + err);
      });
      appState.setUser(null);
      window.localStorage.clear();
      client.resetStore();
      window.location.reload();
    }
  };

  const drawer = (
    <div>
      <div className={classes.toolbar} />
      <List className={classes.listStyle}>
        <ListItem
          button
          className={classes.listItemStyle}
          onClick={() => handleNavigation(HOME_PAGE)}
        >
          <ListItemIcon className={classes.listItemIconStyle}>
            <HomeIcon className={classes.navIconStyle} />
          </ListItemIcon>
          <ListItemText
            classes={{ primary: classes.linkStyle }}
            primary="Home"
          />
        </ListItem>
        <ListItem button className={classes.listItemStyle}>
          <ListItemIcon className={classes.listItemIconStyle}>
            <CachedIcon className={classes.navIconStyle} />
          </ListItemIcon>
          <ListItemText
            classes={{ primary: classes.linkStyle }}
            primary="Ongoing Tasks"
          />
        </ListItem>
        <ListItem button className={classes.listItemStyle}>
          <ListItemIcon className={classes.listItemIconStyle}>
            <RestoreIcon className={classes.navIconStyle} />
          </ListItemIcon>
          <ListItemText
            classes={{ primary: classes.linkStyle }}
            primary="Task History"
          />
        </ListItem>
        <ListItem button className={classes.listItemStyle}>
          <ListItemIcon className={classes.listItemIconStyle}>
            <FAQIcon />
          </ListItemIcon>
          <ListItemText
            classes={{ primary: classes.linkStyle }}
            primary="FAQ"
          />
        </ListItem>
      </List>
    </div>
  );

  const profileAvatar = (
    <Box>
      <Button onClick={onProfileAvatarClick}>
        <Avatar style={{ backgroundColor: "transparent" }}>
          <AccountCircleIcon className={classes.accIcon} />
        </Avatar>
      </Button>
      <Menu
        anchorEl={profileAnchor}
        keepMounted
        open={Boolean(profileAnchor)}
        onClose={closeProfileMenu}
      >
        <MenuItem onClick={onLogOut}>Logout</MenuItem>
      </Menu>
    </Box>
  );

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            aria-label="open drawer"
            edge="start"
            onClick={toggleDrawer}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>

          <Box width={1} className={classes.appBarContainer}>
            <Link
              classes={{ root: classes.anchor }}
              onClick={() => handleNavigation(HOME_PAGE)}
            >
              <Box mx={1} my={1}>
                <img src={web_logo} alt="Logo" width="55" height="80" />
              </Box>
            </Link>
            <Box mx={1} my={0.5}>
              {profileAvatar}
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer}>
        <Hidden smUp implementation="css">
          <Drawer
            variant="temporary"
            open={mobileMode}
            onClose={toggleDrawer}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {props.children}
      </main>
      <AcceptCookie />
    </div>
  );
}

export default AppDrawer;
