import { Box, Checkbox, Typography } from "@material-ui/core";
import React, { useContext, useState } from "react";
import { ImportSectionContext } from "../../contexts/app_context";

export default function SectionTile({ sectionData }) {
  const [check, setCheck] = useState(false);
  const importSectionCntx = useContext(ImportSectionContext);
  const handleCheck = () => {
    setCheck(!check);
    const { sections, setSections } = importSectionCntx;
    const index = sections.indexOf(sectionData.id);
    if (index > -1) {
      sections.splice(index, 1);
    } else {
      sections.push(sectionData.id);
    }
    setSections([...sections]);
  };

  return (
    <Box className="section-tile">
      <Checkbox checked={check} onChange={handleCheck} color="primary" />
      <Typography variant="subtitle1" color="textPrimary">
        {sectionData.title}
      </Typography>
    </Box>
  );
}
