import React, { useContext, useState, useEffect } from "react";
import { Box, Typography, Switch, Select, MenuItem } from "@material-ui/core";
import { FormBuilderContext } from "../../../contexts/app_context";
import {
  getQuestionTextfield,
  getRequiredText,
} from "../../../utils/utilities";
import CustomTooltip from "../../CustomToolTip";
import getFormbuilderIcon from "../../../utils/custom-icons";

export default function ImageTypeField({ element }) {
  const itemContext = useContext(FormBuilderContext);
  const [fields, setFields] = useState({
    label: element.onResult ? element.onResult.label : "",
    labelError: element.onError,
    count: element.onResult ? element.onResult.count : 1,
    required: element.onResult ? element.onResult.required : false,
  });

  const setLabel = (event) => {
    const value = event.target.value.trim();

    setFields((prevState) => ({
      ...prevState,
      label: value,
      labelError: value ? false : true,
    }));
  };

  const setCount = (event) => {
    const value = event.target.value;

    setFields((prevState) => ({
      ...prevState,
      count: value,
    }));
  };

  const submitJson = () => {
    if (fields.label) {
      itemContext.updateItem({
        item: element,
        data: {
          type: element.type,
          label: fields.label,
          count: fields.count,
          response: [],
          required: fields.required,
        },
      });
    } else {
      itemContext.updateItem({
        item: element,
        data: null,
      });
    }
  };

  useEffect(() => {
    submitJson();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields]);

  return (
    <Box className="question-container">
      <Box className="item-icon-style">{getFormbuilderIcon(element.type)}</Box>
      <Box className="question-fields">
        <Box>
          {getRequiredText("Enter your question", "h6")}
          {getQuestionTextfield(
            fields.label,
            fields.labelError,
            setLabel,
            "Please describe the question for the images field here..."
          )}
        </Box>
        <Box className="image-count-field">
          <Box>
            <Typography color="textPrimary" variant="h6">
              Count
            </Typography>
            <CustomTooltip content="Select the number of images you would like to receive for this question." />
          </Box>
          <Select value={fields.count} onChange={setCount}>
            <MenuItem value={1}>1</MenuItem>
            <MenuItem value={2}>2</MenuItem>
            <MenuItem value={3}>3</MenuItem>
            <MenuItem value={4}>4</MenuItem>
            <MenuItem value={5}>5</MenuItem>
          </Select>
        </Box>

        <Box className="required-switch">
          <Box>
            <Typography color="textPrimary" variant="h6">
              Required
            </Typography>
            <CustomTooltip content="Don't allow to submit the form, if this question is not answered." />
          </Box>
          <Switch
            checked={fields.required}
            onChange={(e) => {
              setFields((prevState) => ({
                ...prevState,
                required: e.target.checked,
              }));
            }}
            color="primary"
          />
        </Box>
      </Box>
    </Box>
  );
}
