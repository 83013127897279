import React from "react";
import c3 from "c3";

export default function CustomChart({ title, values, colors }) {
  React.useEffect(() => {
    c3.generate({
      data: {
        columns: values,
        type: "donut",
        colors: colors,
      },
      donut: {
        title: title,
        label: {
          format: (value, ratio, id) => {
            return title === "Budget" ? `£ ${value}` : value;
          },
        },
      },
      bindto: `#${title}`,
    });
  });

  return <div className="chart" id={title} />;
}
