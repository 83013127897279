import React from "react";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { Box, makeStyles, Typography, Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { commonStyles } from "../../styles/material-styles";

const useStyles = makeStyles((theme) => ({
  buttonStyle: {
    borderRadius: 40,
    textTransform: "none",
    fontSize: 18,
  },
}));

export default function SuccessPage() {
  const custom = useStyles();
  const common = commonStyles();
  const history = useHistory();

  const handleClick = () => {
    history.replace("/");
  };

  return (
    <Box className={common.centerContainer}>
      <CheckCircleIcon className={common.successIconStyle} />

      <Typography
        style={{ fontWeight: "bold" }}
        color="textPrimary"
        variant="h3"
      >
        Payment Successful!
      </Typography>
      <Typography className={common.descStyle} color="textPrimary" variant="h6">
        Thank you for your payment. An automated payment receipt will be sent to
        your registered mail and your task will be live on the specified date.
      </Typography>
      <Button
        className={custom.buttonStyle}
        variant="contained"
        color="primary"
        onClick={handleClick}
      >
        Go to Home
      </Button>
    </Box>
  );
}
